<template>

    <div class="view">
      <div class="table-container">
      
      <v-data-table
          :headers="headers"
          :items="tableItems"
          dense
          :hide-default-footer="true"
          :disable-pagination="true"
          no-data-text="No messages"
          :show-expand="false"
          :item-class="row_classes"
          :id="`${parent}_message_table`"
          :key="`${parent}_message_table_${refresh}`"
          :fixed-header="true"
          class="flex-table"
          width="100%"
          :loading="loading"
      >
          <template v-slot:item.to="{item}">
            <span class="caption" :style="basicStyle" >{{item.to}}</span>
          </template>

          <template v-slot:item.from="{item}">
            <div
              :class="item.fromClass" 
            >
              <span class="text-subtitle-2" :style="basicStyle">{{item.from}}</span>
            </div>
            <div
              v-if="item.callback"
              >
              <span class="text-subtitle-2" :style="basicStyle">{{item.callback}}</span>
            </div>
            <div
              v-if="item.fromFacility"
              >
              <span class="text-subtitle-2" :style="basicStyle">{{item.fromFacility}}</span>
            </div>

          </template>


          <template v-slot:item.patient="{item}">
            <div>
              <span class="text-subtitle-2 ycmd-very-lightest-gray fw-400 br-2 pl-1 pr-1" :style="basicStyle">{{item.patient_name}}</span>
            </div>
            <div>
              <span class="text-subtitle-2 pl-1 pr-1" :style="basicStyle">{{item.patient_dob}}</span>
            </div>
            <div>
              <span
                class="text-subtitle-2 pl-1 pr-1"
                :style="basicStyle"
                v-if="item && item.patient_status"
                >{{item.patient_status}}</span>
            </div>
          </template>
          <template v-slot:item.message="{item}">
            <div>
              <span  class="text-caption ycmd-medium-gray--text "  :style="basicStyle">Message: </span>
              <div :class="item.messageClass"  :style="basicStyle">{{item.contents}}</div>
            </div>
            <div v-if="item.showResponse">
              <span  class="text-caption ycmd-medium-gray--text "  :style="basicStyle">Response: </span>
              <div :class="item.responseClass"  :style="basicStyle">{{item.response}}</div>
            </div>
          </template>          
          <template v-slot:item.fields="{item}">            
            <div
              v-for="field in item.fields"
              :key="`f_${item.id}_${field.name}`"
            >
              <span  class="text-caption ycmd-medium-gray--text "  :style="basicStyle">{{field.name}}:</span>
              <span class="text-subtitle-2 "  :style="basicStyle">{{field.value}}</span>

            </div>
            <div
              v-if="item.hasMoreFields"
            >
              <v-icon
              size="small"
              class="ycmd-medium-blue--text"
              style="pointer: cursor !important;"
              @click="loadFields(item)"
              >
                more_horiz
              </v-icon>
            </div>
          </template>   

          <template v-slot:item.date="{item}">            
            <div>
              <span class="text-caption ycmd-medium-gray--text "  :style="basicStyle">Sent: </span>
              <span class="text-subtitle-2 "  :style="basicStyle">{{ item.date_created }}</span>
            </div>
            <div>
              <span class="text-caption ycmd-medium-gray--text "  :style="basicStyle">Last Sent: </span>
              <span class="text-subtitle-2 "  :style="basicStyle">{{ item.last_sent }}</span>
            </div>

            <div v-if="!item.date_confirmed && item.canConfirm">
                <v-btn dark x-small text
                    class="ycmd-red"
                    @click="doAction({name: 'Confirm Message', function: 'confirmMessage'}, item)"
                    :disabled="processing"
                >
                Confirm
              </v-btn>
            </div>
            <div v-else>
              <span class="text-caption ycmd-medium-gray--text "  :style="basicStyle">Confirmed: </span>
              <span v-if="item.date_confirmed" :class="item.confirmedClass"  :style="basicStyle">{{ item.date_confirmed }}</span>              
              <span v-else :class="item.confirmedClass"  :style="basicStyle">Unconfirmed</span>              
            </div>
            <div
              v-if="item.deviceName || item.bypassed"
            >
              <span v-if="item.deviceName"  class="text-subtitle-2 "  :style="basicStyle" >{{ item.deviceName }}</span>
              <span v-if="item.bypassed"  class="text-subtitle-2 float-right pt-1" :style="basicStyle" >Bypassed</span>
            </div>

          </template>

          <template v-slot:item.actions="{item}">

            <v-tooltip 
                        bottom
                        nudge-bottom="5"
                        color="ycmd-black"
                        content-class="fade-0">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="ycmd-blue"
                  class="pointer"
                  :size="22"
                  @click="doSelect(item)"  
                >
                  {{item.selected ? 'check_circle_outline': 'radio_button_unchecked'}}
                  
                </v-icon>
              </template>
              <span>Select</span>
            </v-tooltip>

            <template v-for="(action, i) in actionList">
              <template v-if="(action.name !== 'Trash')">
                <template v-if="action.name !== 'Confirm Message'">
                  <v-tooltip
                    v-if="action.condition ? action.condition({ item }) : true"
                    :key="`item_id_${ i }`"
                      bottom
                      nudge-bottom="5"
                      color="ycmd-black"
                      content-class="fade-0"
                    >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        @click="doAction(action, item)"
                        v-on="on"
                        color="ycmd-blue"
                        class="pointer"
                        :size="22">{{ action.iconCondition ? action.iconCondition() : action.icon }}
                      </v-icon>                    
                    </template>
                    <span>{{ action.name }}</span>
                  </v-tooltip>
                </template>
              </template>
            </template>
          </template>                       

      </v-data-table>
      </div>
    </div>


</template>

<script>

import GET_RECORDING_URL from '@/graphql/queries/phoenix/ycmdRecordingUrlGet';
import {mapActions, mapState, mapGetters} from "vuex";
import {formatDate, formatDOB} from '@/methods/formatters';
import {formatPhoneNumber} from '@/methods/globalMethods';

export default {
  name: 'messagesListContentGrid',
  props: {
    items: Array,
    parent: {
      type: String,
      default: 'tab-inbox'
    },
    loading: {
      type: Boolean,
      default: false
    },
    actionList: {
      type: Array,
      default: () => []
    },
    reset: {
      type: Number,
      default: 0
    },
    mailbox: {
      type: String,
      default: 'inbox'
    },
    fontAdjustment: {
      type: Number,
      default: 0
    }, 


  },
  components: {
  },
  data: () => ({
    tableItems: [],
    processing: false,
    selectedAction: null,
    selectedId: null,
    refresh: 0,
    dateFormat: 'MM-dd-yyyy h:mm aaa'
  }),
  mounted() {
    //console.log('items', this.items)
    this.loadItems()
    //console.log('this.el', this.$el)

    this.setScroll()

  },
  methods: {
    setScroll () {

      //we could do scrolling ourself and emit a dataneeded event but for now, just give the parent the 
      //scrolling element and let it handle everything since it has to do that for the formview anyway
      //
      //vmm - when switching tabs, scrolling element seems to get lost.  Probably cached in shadow dom somewhere 
      // we will set up a local scroll and emit it instead

      let messagetable = this.$el.querySelector(`#${this.parent}_message_table`)
      console.log('setscroll messagetable', messagetable)
      if (messagetable) {
          let scrollElement = messagetable.querySelector('div.v-data-table__wrapper')
          console.log('setscroll scrollElement', scrollElement)

          if (scrollElement) {
            //this.$emit('scrollElementChanged', scrollElement)
            scrollElement.addEventListener('scroll', this.localScroll, false)
          }

      }
    },
    localScroll (params) {
      console.log('localScroll')
      this.$emit('gridScroll', params)
    },
    async doAction(action, item) {
      // console.log('action: ', action);
      // console.log('item: ', item);

      if (this.processing) {
        return
      }

      this.processing = true;
      this.selectedAction = action.name;
      this.selectedId = item.id;

      //this.refresh += 1

      //await this[action.function](item);

      let params = {
        action, 
        item, 
        completeCallback: this.actionComplete
      }

      this.$emit('doAction', params)

    },
    actionComplete(action, item, result) {
      this.processing = false;
      this.selectedAction = null;
      this.selectedId = null;
      //this.refresh += 1
    },

    doSelect(item) {
      this.$emit('onselected', item, !item.selected)
    },
    loadFields(item) {
      for (let i = item.fields.length; i < item.allFields.length; i++) {
        item.fields.push({
          name: item.allFields[i].label,
          value: item.allFields[i].formatted_value
        })
      }
      item.hasMoreFields = false
    },
    row_classes(item) {
      let classes = ['pa-0', 'ma-0']

      if (item.odd) {
        classes.push('odd')
      }

      return classes
    },
    loadItems () {
        let arr = []
        let i=0
        this.items.map(item => {
          let translated = this.translateItem(item)
          translated.odd = i++ % 2 
          arr.push(translated)
        })
        this.tableItems = arr
    },
    translateItem(item) {
      let i = {
        id: item.id,
        cssclass: "ma-0 pa-0"
      }

      if (item.to_user) {
        i.to = `${item.to_user.first_name} ${item.to_user.last_name} `
      } else {
        i.to = 'unknown'
      }

      i.contents = item.contents ? item.contents : 'Message N/A'

      if (item.patient) {
        i.patient_name = `${item.patient.first_name} ${item.patient.last_name}` 
        i.patient_dob = formatDOB({date: item.patient.dob})
      }
      if (item.patient_status) {
        i.patient_status = item.patient_status === 'established' ? 'Established' : 'New'
      }

      let sorted = item.fields.sort((a,b) => {
        return a.label.localeCompare(b.label);
      })
      i.allFields = sorted

      let numberOfFields = 2
      i.fields = []
      for (let j = 0;j<i.allFields.length && j < numberOfFields;j++) {
        i.fields.push({
          name: i.allFields[j].label,
          value: i.allFields[j].formatted_value
        })
      }

      i.hasMoreFields = i.allFields.length > numberOfFields
      i.date_created = formatDate({date:item.date_created, isUTC: false, overrideFormat: this.dateFormat})
      i.date_confirmed = item.date_confirmed ? formatDate({date:item.date_confirmed, isUTC: false, overrideFormat: this.dateFormat}) : null
      i.last_sent = formatDate({date:item.last_sent, isUTC: false, overrideFormat: this.dateFormat})
      i.selected = item.selected

      let messageColor = 'ycmd-very-lightest-gray'
      i.confirmedClass=" text-subtitle-2"

      if (!item.date_confirmed) {
        //messageColor = 'ycmd-red'
        i.confirmedClass = `text-subtitle-2 ${messageColor} fw-400 br-2 pl-1`
      }
      i.messageClass = `text-subtitle-2 ${messageColor} fw-400 br-2 pl-1`

      let responseColor = 'ycmd-lightest-blue'
      
      if (item.response && item.response != '0'){
        i.response = item.response
        i.showResponse = true
      } else {
        i.response = 'No response'
        responseColor = 'ycmd-light-orange'
        i.showResponse = this.nurseView ? true : false //always show no response to nurses but others don't care
      }

      i.responseClass = `text-subtitle-2 ${responseColor} fw-400 br-2 pl-1`

      if (!i.date_confirmed) {
        if (this.mailbox != 'sent') {
          i.canConfirm = true
        }
      }

      if (item.guest_name) {
        i.from = `On behalf of ${item.guest_name}`
      } else {
        i.from = `${item.from_user.first_name} ${item.from_user.last_name}`
        i.fromClass = "ycmd-medium-gray--text"
      }
      if (item.callback) {
        i.callback = formatPhoneNumber(item.callback)
      }
      
      i.fromFacility = `${item.facility_name} ${item.facility_unit} `

      if (this.nurseView) {

      } else {
        i.deviceName = item.device ? item.device.name : null
        if (item.bypass_coverage) {
          i.bypassed = true
        }
      }

      return i
    }
  },
  computed: {
    ...mapGetters('profile', ['getLegacyRoles']),
    legacyRoleMap () {
        let map = {}
        let roles = this.getLegacyRoles
        console.log('roles', roles)
        if (roles && roles.length) {
            roles.map(r => {
                map[r] = true
            })
        }
        return map
    },
    nurseView () {
        if (this.legacyRoleMap['provider']) {
            return false
        }
        if (this.legacyRoleMap['member']) {
            return false
        }
        if (this.legacyRoleMap['nurse']) {
            return true
        }

        return false

    },
    basicStyle () {
      return `font-size: ${10 + this.fontAdjustment}px !important;line-height: ${12 + this.fontAdjustment}px !important;`
    },
    headers () {
      let headers
      if (this.nurseView) {
        headers = [
            {
                text: 'To',
                value: 'to',
                width: '15%',
                sortable: false,
            },
            {
                text: 'Patient',
                value: 'patient',
                width: '10%',
                sortable: false,
            },
            {
                text: 'Message',
                value: 'message',
                width: '25%',
                sortable: false,
            },
            {
                text: 'Fields',
                value: 'fields',
                width: '28%',
                sortable: false,
            },
            {
                text: 'Date',
                value: 'date',
                width: '22%',
                sortable: false,
            },
            {
                text: '',
                value: 'actions',
                width: '5px',
                align: 'center',
                sortable: false,
            },
        ]

      } else {
        headers = [
            {
                text: 'From',
                value: 'from',
                width: '20%',
                sortable: false,
            },          
            {
                text: 'Patient',
                value: 'patient',
                width: '10%',
                sortable: false,
            },
            {
                text: 'Message',
                value: 'message',
                width: '25%',
                sortable: false,
            },
            {
                text: 'Fields',
                value: 'fields',
                width: '23%',
                sortable: false,
            },
            {
                text: 'Date',
                value: 'date',
                width: '22%',
                sortable: false,
            },
            {
                text: '',
                value: 'actions',
                width: '5px',
                align: 'center',
                sortable: false,
                class: 'actions'
            },
        ]        
      }

      return headers
    },  
  },
 
  watch: {
      items (newValue, oldValue) {
        this.loadItems()
      },
      reset (newValue, oldValue) {
        //let the element get created before we try to scroll
        setTimeout(()=> {
          this.setScroll()
        }, 2000)        
      },
      parent (newValue, oldValue) {
        console.log('parent reset')
        setTimeout(()=> {
          this.setScroll()
        }, 2000)        
      }

  }
}
</script>
<style scoped>

  #tab-inbox_message_table >>> th, #tab-inbox_message_table >>> td,
  #tab-sent_message_table >>> th, #tab-sent_message_table >>> td,
  #tab-trash_message_table >>> th, #tab-trash_message_table >>> td,
  #tab-inbox-group_message_table >>> th, #tab-inbox-group_message_table >>> td,
  #tab-sent-group_message_table >>> th, #tab-sent-group_message_table >>> td,
  #tab-trash-group_message_table >>> th, #tab-trash-group_message_table >>> td,
  #tab-forwarding_message_table >>> th, #tab-forwarding_message_table >>> td
  {
    padding-left: 2px;
    padding-right: 2px;
  }

.view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}


.table-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.flex-table {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.flex-table > div {
  width: 100% !important;
}
#tab-inbox_message_table >>> div.v-data-table__wrapper, 
#tab-sent_message_table >>> div.v-data-table__wrapper,
#tab-trash_message_table >>> div.v-data-table__wrapper,
#tab-inbox-group_message_table >>> div.v-data-table__wrapper, 
#tab-sent-group_message_table >>> div.v-data-table__wrapper,
#tab-trash-group_message_table >>> div.v-data-table__wrapper,
#tab-forwarding_message_table >>> div.v-data-table__wrapper 
{
  width: 100% !important;
}
#tab-inbox_message_table >>> td, 
#tab-sent_message_table >>> td,
#tab-trash_message_table >>> td,
#tab-inbox-group_message_table >>> td, 
#tab-sent-group_message_table >>> td,
#tab-trash-group_message_table >>> td,
#tab-forwarding_message_table >>> td 
{
  vertical-align: top;
}

#tab-inbox_message_table >>> tr.odd, 
#tab-sent_message_table >>> tr.odd,
#tab-trash_message_table >>> tr.odd,
#tab-inbox-group_message_table >>> tr.odd, 
#tab-sent-group_message_table >>> tr.odd,
#tab-trash-group_message_table >>> tr.odd,
#tab-forwarding_message_table >>> tr.odd 
{
  background-color:  #F0F9FF;
}

</style>