import axios from "axios";
import ycmdBadgeCount from '@/graphql/queries/phoenix/ycmdBadgeCount'
import ycmdReportDefinitionGet from '@/graphql/queries/phoenix/ycmdReportDefinitionGet'
import ycmdReportRun from '@/graphql/queries/phoenix/ycmdReportRun'
import ycmdChatRoomGet from "@/graphql/queries/phoenix/ycmdChatRoomGet";
import ycmdFacilitySearch from '@/graphql/queries/phoenix/ycmdFacilitySearch'
import ycmdMessageGet from '@/graphql/queries/phoenix/ycmdMessageGet';
import ycmdProfileUpdate from "@/graphql/mutations/phoenix/ycmdProfileUpdate";
import ycmdUserSettings from "@/graphql/mutations/phoenix/ycmdUserSettings";
import ycmdAuthenticateTokenRefresh from '@/graphql/mutations/phoenix/ycmdAuthenticateTokenRefresh'
import ycmdChatPostGet from '@/graphql/queries/phoenix/ycmdChatPostGet';
import ycmdChatPostDetailGet from "@/graphql/queries/phoenix/ycmdChatPostDetailGet";
import ycmdChatPostCreate from '@/graphql/mutations/phoenix/ycmdChatPostCreate';
import ycmdChatSearch from '@/graphql/queries/phoenix/ycmdChatSearch';
import ycmdChatAttachmentUrlGet from '@/graphql/queries/phoenix/ycmdChatAttachmentUrlGet';  
import ycmdChatRoomCreate from "@/graphql/mutations/phoenix/ycmdChatRoomCreate";
import ycmdChatRoomParticipantRemove from "@/graphql/mutations/phoenix/ycmdChatRoomParticipantRemove";
import ycmdChatRoomClearUnread from "@/graphql/mutations/phoenix/ycmdChatRoomClearUnread";
import ycmdReportScheduleCreate from "@/graphql/mutations/phoenix/ycmdReportScheduleCreate";
import ycmdReportScheduleDelete from "@/graphql/mutations/phoenix/ycmdReportScheduleDelete";
import ycmdProfileGet from "@/graphql/queries/phoenix/ycmdProfileGet";
import providerPageGet from "@/graphql/queries/provider-page/providerPageGet";
import providerPageSet from "@/graphql/mutations/provider-page/providerPageSet";
import emrGet from "@/graphql/queries/emr/emrGet";
import emrOperation from "@/graphql/mutations/emr/emrOperation";
import phxGroups from '@/graphql/queries/phxGroups';
import ycmdUserSearch from '@/graphql/queries/phoenix/ycmdUserSearch'
import ycmdTicketCreate from "@/graphql/mutations/phoenix/ycmdTicketCreate";


import { print } from 'graphql';

function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  } 

const dataObjects = {
    ycmdBadgeCount: {
        authentication: 'mongo', //mongo, cognito, or apikey
        query: ycmdBadgeCount
    },
    ycmdReportDefinitionGet: {
        authentication: 'mongo', //mongo, cognito, or apikey
        query: ycmdReportDefinitionGet
    },
    ycmdUserSearch: {
        authentication: 'mongo', //mongo, cognito, or apikey
        query: ycmdUserSearch
    },
    ycmdReportRun: {
        authentication: 'mongo', //mongo, cognito, or apikey
        query: ycmdReportRun
    },
    ycmdFacilitySearch: {
        authentication: 'mongo', //mongo, cognito, or apikey
        query: ycmdFacilitySearch
    },
    ycmdMessageGet: {
        authentication: 'mongo', //mongo, cognito, or apikey
        query: ycmdMessageGet
    },
    ycmdProfileUpdate: {
        authentication: 'mongo', //mongo, cognito, or apikey
        mutation: ycmdProfileUpdate
    },
    ycmdUserSettings: {
        authentication: 'mongo', //mongo, cognito, or apikey
        mutation: ycmdUserSettings
    },
    ycmdAuthenticateTokenRefresh: {
        authentication: 'apikey',
        mutation: ycmdAuthenticateTokenRefresh
    },
    ycmdChatRoomGet: {
        authentication: 'mongo',
        query: ycmdChatRoomGet
    },
    ycmdChatPostGet: {
        authentication: 'mongo',
        query: ycmdChatPostGet
    },
    ycmdChatPostDetailGet: {
        authentication: 'mongo',
        query: ycmdChatPostDetailGet
    },
    ycmdChatPostCreate: {
        authentication: 'mongo',
        mutation: ycmdChatPostCreate
    },
    ycmdChatSearch: {
        authentication: 'mongo',
        query: ycmdChatSearch
    },
    phxGroups: {
        authentication: 'mongo',
        query: phxGroups
    },
    ycmdChatAttachmentUrlGet: {
        authentication: 'mongo',
        query: ycmdChatAttachmentUrlGet
    },
    ycmdChatRoomCreate: {
        authentication: 'mongo',
        mutation: ycmdChatRoomCreate
    },    
    ycmdChatRoomParticipantRemove: {
        authentication: 'mongo',
        mutation: ycmdChatRoomParticipantRemove
    },    
    ycmdChatRoomClearUnread: {
        authentication: 'mongo',
        mutation: ycmdChatRoomClearUnread
    },    
    ycmdProfileGet: {
        authentication: 'mongo',
        query: ycmdProfileGet
    },   
    providerPageGet: {
        authentication: 'apikey',
        query: providerPageGet
    }, 
    providerPageSet: {
        authentication: 'apikey',
        mutation: providerPageSet
    },  
    emrGet: {
        authentication: 'mongo',
        query: emrGet
    },
    ycmdReportScheduleCreate: {
        authentication: 'mongo',
        mutation: ycmdReportScheduleCreate
    },
    ycmdReportScheduleDelete: {
        authentication: 'mongo',
        mutation: ycmdReportScheduleDelete
    },
    emrOperation: {
        authentication: 'mongo',
        mutation: emrOperation
    }, 
    ycmdTicketCreate: {
        authentication: 'mongo',
        mutation: ycmdTicketCreate
    },                
}

const setUpHeaders = (dobj, headers) => {
    if (dobj.authentication === 'mongo') {
        headers['x-api-key'] = process.env.VUE_APP_APPSYNC_API_KEY
        headers.Authorization = localStorage.getItem('accessToken')
    }
    else if (dobj.authentication === 'cognito') {
        headers.Authorization = localStorage.getItem('accessTokenPCP')
    }
    else if (dobj.authentication === 'apikey') {
        headers['x-api-key'] = process.env.VUE_APP_APPSYNC_API_KEY
    }    
}

const refreshToken = async () => {

    //when the token expires, multiple requests will be going off, each trying to refresh the token
    //we will use localStorage as a semaphore and let one request refresh the token and the others
    //will have to wait

    if (localStorage.getItem("refreshingToken") === 'true') {
        console.log('currently refreshing token')
        return {status: 'wait'}
    }
    localStorage.setItem("refreshingToken", true)

    let url = process.env.VUE_APP_APPSYNC_URL;

    let dobj = dataObjects['ycmdAuthenticateTokenRefresh']
    
    let data = {}

    data.query = dobj.mutation
    if (typeof(data.query === 'object')) {
        data.query = print(data.query)
    }

    data.variables = {
        refresh_token: localStorage.getItem("refreshToken"),
        username: localStorage.getItem("username"),
        user_id: localStorage.getItem("userID")
    }

    let headers = {
        'Content-Type': 'application/graphql',
        'cache-control': 'no-cache',
    }
    
    headers['x-api-key'] = process.env.VUE_APP_APPSYNC_API_KEY
    try {
  
        let response = await axios.post(url, data, {headers: headers}, {timeout: 180000});

        console.log('refresh response: ', response);
        if (response && 
            response.data && 
            response.data.data && 
            response.data.data.ycmdAuthenticateTokenRefresh) {            
            
            if (response.data.data.ycmdAuthenticateTokenRefresh.authentication_status &&
                response.data.data.ycmdAuthenticateTokenRefresh.authentication_status.prompt_for_twofactor  ){
                    store.commit('app/setAuthenticationStatus', response.data.data.ycmdAuthenticateTokenRefresh.authentication_status)
                    store.commit('app/setAuthenticationStatusOpen', true)
            }

            localStorage.setItem("refreshingToken", false)

            if (response.data.data.ycmdAuthenticateTokenRefresh.token) {
                console.log('INFO: AXIOS client Refreshed the token successfully!');
                localStorage.setItem("accessToken", response.data.data.ycmdAuthenticateTokenRefresh.token);
                return {status: 'success'}
            } else {
                console.log("WARNING: Access token couldn't be retrieved from the refresh token");
                console.log('response for refresh failure: ', response, 'timeleft: ', timeLeft);
                return {status: 'error'}
            }
            
        }
    } catch (err) {
        localStorage.setItem("refreshingToken", false)
        console.log('error during axios token refresh', err)
        return {status: 'error'}
    }
}

const waitUntilRefreshDone = async () => {
    for (let i=0;i<60;i++) {
        if (localStorage.getItem("refreshingToken") !== 'true') {
            return
        }

        console.log('currently refreshing.  check in a sec')
        await sleep(1000)
    }
}


const post = async (dobj, params) => {
    let url = process.env.VUE_APP_APPSYNC_URL;

    let data = {}

    let query = dobj.query ? dobj.query : dobj.mutation

    if (typeof(query === 'object')) {
        query = print(query)
    }
    data.query = query
    data.variables = params || {}

    let headers = {
        'Content-Type': 'application/graphql',
        'cache-control': 'no-cache',
    }

    setUpHeaders(dobj, headers)
/*
    if (dobj.authentication === 'mongo') {
        headers['x-api-key'] = process.env.VUE_APP_APPSYNC_API_KEY
        headers.Authorization = localStorage.getItem('accessToken')
    }
    else if (dobj.authentication === 'cognito') {
        headers.Authorization = localStorage.getItem('accessTokenPCP')
    }
    else if (dobj.authentication === 'api') {
        headers['x-api-key'] = process.env.VUE_APP_APPSYNC_API_KEY
    }
*/
    try {
        let result = await axios.post(url, data, {headers: headers}, {timeout: 180000});
        let examined = examineResults(result.data)
        if (examined.status === 'success') {
            return result.data
        } else if (examined.status === 'token_expired') {

            console.log('axios client refreshing token')

            let tokenRefresh = await refreshToken()
            if (tokenRefresh.status === 'wait') {
                await waitUntilRefreshDone()
            }

            setUpHeaders(dobj, headers)
            //resubmit the request
            let result2 = await axios.post(url, data, {headers: headers}, {timeout: 180000});

            return result2.data
        } else {
            console.log('an error has occurred', examined, result)
            return examined
        }
    }
    catch (e) {

        console.log('ERROR: Retrieving refresh token from the backend: ', JSON.stringify(e))
        return null;
    }
}

const examineResults = (result) => {

    if (!result.errors && result.data) {

        return {
            status: "success"
        }
    }

    let tokenExpired = false
    let errorMessage = ''
    if (Array.isArray(result.errors)) {
        result.errors.map(e => {
            if (e.errorType == 'TokenExpiredError') {
                tokenExpired = true
            } else {
                errorMessage = 'An error has occurred'
            }
        })
    }

    if (tokenExpired) {
        return {
            status: 'token_expired'
        }
    } else {
        let error
        if (result.errors && result.errors.length) {
            error = result.errors[0]
        }
        if (error && error.errorInfo) {
            error = error.errorInfo
        }
        
        if (!error) {
            error = {
                message: "An error has occurred", 
                code: "generalError", 
                statusCode: 500, 
                retryable: false
            }
        }
        return {error: error}        
    }
}


const dataClient = async (dobjKey, params) => {
    let dobj = dataObjects[dobjKey]

    if (!dobj) {
        throw `Invalid data object ${dobjKey}`
    }
    let response = await post(dobj, params)

    if (response && response.data && response.data[dobjKey]) {
        return response.data[dobjKey]
    } 
    return response
}

export default dataClient;
