<template>

    <v-autocomplete
        v-model="localValue"
        :items="items"
        outlined
        dense
        chips
        small-chips
        :label="label"
        item-value="id"
        :item-text="getName"
        :multiple="!single"
        @change="changed"
        background-color="white"
    ></v-autocomplete>

</template>

<script>
import ycmdUserSearch from '@/graphql/queries/phoenix/ycmdUserSearch'

    export default {
        name: 'searchBar',
        components: {
        },
        props: {
            label: {
                type: String,
                default: 'Select the users'
            },   
            value: {
                default: null
            },
            roles: {
                type: Array,
                default: () => []
            },  
            include_searching_user: {
                type: Boolean,
                default: false
            },  
            exclude_group_filter: {
                type: Boolean,
                default: false
            },  
            single: {
                type: Boolean,
                default: false
            }  

        },
        data: () => ({
            items: [],
            localValue: null
        }),
        async mounted() {
            if (!this.items || !this.items.length) {
                await this.loadUsers()
            }
            this.localValue = this.value
        },
        methods: {
            changed (params) {
                this.$emit('input', this.localValue)
            },
            getName(item) {              
              return `${item.first_name} ${item.last_name}`
            },
            async loadUsers() {
                let response = await this.$apollo.query({
                    query: ycmdUserSearch,
                    variables: {
                        roles: this.roles,
                        include_searching_user: this.include_searching_user,
                        exclude_group_filter: this.exclude_group_filter
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                let all = response.data.ycmdUserSearch.find(r => {
                    return r.group_id === '0'
                })
                let sorted = all.users.sort( (a,b) => {
                    let asortable = (a.first_name ? a.first_name : '') + (a.last_name ? a.last_name : '')
                    let bsortable = (b.first_name ? b.first_name : '') + (b.last_name ? b.last_name : '')
                    return asortable.localeCompare(bsortable)
                })
                this.items = sorted
                this.$emit('update:users', sorted)
            }
        },
        computed: {
        },
        watch: {
            value (newValue, oldValue) {
                if (newValue !== this.localValue) {
                    this.localValue = newValue
                }
            }
        }
    }
</script>
