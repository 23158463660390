<template>
  <div>
    <div v-for="(post, index) in getCurrentRoomPosts"
          :key="`post_${index}`"
          :value="post.id"
          :class="getImageClass(post.attachment_id)">

      <template v-if="post.grouped_date">
        <div class="d-flex align-center justify-center flex-grow-1 fs-13 fade-1 ml-4 mr-4 pt-4 pb-4"
              style="border-bottom: solid 1px #5894cc">
          {{ post.grouped_date }}
        </div>
      </template>

      <template v-if="post.type === 'system'">
        <div class="d-flex align-center pa-4" style="margin-right: 8px">
          <div class="pl-1 fs-11">{{ post.text }}</div>
          <v-spacer/>
          <div class="pl-4 fade-2 fs-10 no-wrap">
            {{ getTime({ start_date: post.date_created}) }}
          </div>
        </div>
      </template>

      <template v-if="post.type === 'post'">

        <template v-if="post.user_id === info.userID">
          <my-post :post="post" class="pa-4 pt-1 mr-2" style="margin-left: 42px">

          </my-post>
        </template>

        <template v-else>
          <user-post :post="post" class="pa-4 pt-1" style="margin-right: 8px">

          </user-post>
        </template>
      </template>

      <template v-if="post.attachment_id">

          <div
            :class="`d-flex flex-column ${attachmentBackgroundColor(post)} pa-0 my-1 mx-0`"
            :key="`attachment_${getCurrentRoom.id}_${index}_${refreshAttachment}`"
            @click="downloadAttachment(post, $event)"
            >
            <div v-if="!imageMaximized" class="d-flex pa-0 ma-0">
              <div class="fade-2 fs-11 pb-0 pl-5">
                {{ post.user_id === info.userID ? 'You uploaded' : post.first_name + ' ' + post.last_name }}
              </div>
              <v-spacer />
              <div class="fade-2 fs-10 pb-0" style="padding-right: 24px;">
                {{ getTime({ start_date: post.date_created}) }}
              </div>
            </div>
            <div class="pl-1 fs-11 text-center" v-if="post.text">{{ post.text }}</div>
            <chat-attachment class="pb-2"
              :room_id="getCurrentRoom.id"
              :attachment_id="post.attachment_id"
              @loaded="attachmentLoaded"
              :key="`att_${post.attachment_id}_${refreshAttachment}`"
            >

            </chat-attachment>
          </div>

      </template>

    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import myPost from '@/views/apps/chat/components/my-post.vue';
import userPost from '@/views/apps/chat/components/user-post.vue';
import chatAttachment from '@/views/apps/chat/components/attachment.vue';
import { format, utcToZonedTime } from 'date-fns-tz';
import dataClient from '@/graphql/clients/axios';

export default {
  props: {
    roomPosts: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    //scrollContainer: null
    refreshAttachment: 0,
    imageMaximized: false,
    attachmentsToLoad: {},
    posts: []
  }),
  methods: {
    async downloadAttachment(post, ev) {

      if (post.attachment_id) {

        if (ev.target.innerText === 'launch') {
          //since we already have the attachment loaded it is in the cache.
          //get it and create a blank document with an object tag

          let cached = this.getCachedAttachment(post.attachment_id)

          var obj = document.createElement('object');
          obj.style.width = "100%";
          obj.style.height = "100%";
          
          obj.data = cached.attachment

          let  w = window.open("", '_blank');
          w.document.write(obj.outerHTML);
          w.document.close();


        } else {

          let variables = {
            attachment_id: post.attachment_id,
            type: 'download'
          }

          let response = await dataClient('ycmdChatAttachmentUrlGet', variables)
          
          window.open(response.url, '_blank');

        }

      }
    },

    formatPost(text) {
      return text.replace(/\n/g,"<br/>")
    },
    attachmentBackgroundColor (post) {
      return (post.type == 'message' ? 'ycmd-medium-gray  ': ' ycmd-gray--text')
    },
  
    attachmentLoaded (props) {
      /*
      this.attachmentsToLoad[props.attachment_id] = true

      let allLoaded = true
      for (let a in this.attachmentsToLoad) {
        if (!this.attachmentsToLoad[a]) {
          allLoaded = false
          break
        } 
      }
      */
      //if (allLoaded) {

        //just emit all loaded and move to the current bottom.  We will eventually get to the bottom

        this.$emit('attachmentsLoaded', props.room_id)
      //}
    },
    getImageClass(attachment_id) {

      if (attachment_id) {
        if (this.imageMaximized && attachment_id === this.attachment_id) {
          return 'image-maximized'
        }
        else
          return 'image-expandable'
      }
    },


    getTime({ start_date, exact = false }) {

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localDate = utcToZonedTime(start_date, timeZone);

      if(this.exactTimestamps)
        exact = this.exactTimestamps;

      if(!exact)
        return format(localDate, 'h:mm a', { timeZone })
      else
        return format(localDate, 'MM-dd-yyyy h:mm a',{ timeZone })
    },

  },
  computed: {
    ...mapGetters('chat', ['getCurrentRoomPosts', 'getCurrentRoom', 'getCurrentRoomBadgeCount','getCachedAttachment']),    
    ...mapState('profile', ['info']),
    getCurrentRoomPostsids () {
      let ret = []
      this.getCurrentRoomPosts.map(p => {
        ret.push(p.id)
      })
      return ret
    }
  },
  components: {
    'my-post': myPost,
    'user-post': userPost,
    'chat-attachment': chatAttachment
  },
  watch: {
    async getCurrentRoomPosts (newValue, oldValue) {
    //roomPosts (newValue, oldValue) {

      newValue.map(p => {
        if (p.attachment_id && !p.msg_attachment) {
          this.attachmentsToLoad[p.attachment_id] = false
        }
      })
      this.posts=newValue

      this.refreshAttachment += 1

    },
  },
  mounted () {
    this.$emit('scrollElementChanged')
    /*
    let element = this.$el
    while (element) {
      if (element.id === 'chatScrollContainer') {
        this.$emit('scrollElementChanged', element)
        element = null
      } else {
        element = element.parentNode
      }
    }
    */

  }
}
</script>
