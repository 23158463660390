import gql from 'graphql-tag'

export default gql`query ycmdMe($include_linked_users: Boolean) {
	ycmdMe(include_linked_users: $include_linked_users)
	{
		me {
			email
			first_name
			full_name
			group_id
			group {
				name
				id
				voicemail {
					mailboxes {
						name
					}
				}
				forwarding {
					days
    				start_time
    				end_time
				},
				settings {
					chat_allow_same_participant_rooms
				}
			}
			id
			is_deleted
			is_suspended
			last_name
			password_reset_token
			roles
			legacy_roles
			username
			provider_id
			member_id
			pcp_user_id
			pcp_analyst_id
			nurse_id
			reference_id
			online_status
			settings {
				callback_number
				covr_delivery_type
				theme
				enable_chat_notifications
				inactivity_timeout_minutes
				message_view
				message_grid_font_adjustment
				preferences {
					name
					value
				}
				enable_chat_notifications_days {
					day
					start_time
					end_time
				}
				room_settings {
					room_id
					settings {
						enable_chat_notifications
						enable_chat_notifications_days {
							day
							start_time
							end_time
						}
					}
				}
			}
		}
		linked_users {
			email
			first_name
			full_name
			group_id
			group {
				name
				id
			}
			id
			is_deleted
			is_suspended
			last_name
			password_reset_token
			roles
			username
			provider_id
			member_id
			pcp_user_id
			pcp_analyst_id
			nurse_id
			reference_id
		}
		linked_groups {
			user_id
			group_id
			group_name
		}
	}
}`
