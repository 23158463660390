<template>
  <div>

    <ycmd-progress
        :progress="job.progress"
        :progress_description="job.progress_description"
        :job_id="job.job_id"
        v-if="showProgress && running"
        @close="closeProgress"
    />

    <v-btn
        @click="downloadReport()"
        color="primary"
        text
        class="float-right"
        :disabled="false"
        v-if="showExcelLink"
    >
    Download Excel spreadsheet</v-btn>
    <v-btn
        @click="downloadReport()"
        color="primary"
        text
        class="float-right"
        :disabled="false"
        v-if="showCsvLink"
    >
    Download CSV</v-btn>


    <h5
        style="width: 500px;"
        class="pt-5"
    >Properties</h5>
    <v-data-table
        :headers="propertyColumns"
        :items="properties"
        dense
        no-data-text="No properties"
        :hide-default-footer="true"
        :disable-pagination="true"
        :show-expand="false"
        style="width: 500px;"
    >
        <template v-slot:item.value="{ item }">
            {{formatValue(item)}}
        </template>
    </v-data-table>

    <h5
        style="width: 500px;"
        class="pt-5"
        v-if="!job.running"
    >Run</h5>
    <v-data-table
        :headers="statisticColumns"
        :items="statistics"
        dense
        no-data-text="No properties"
        :hide-default-footer="true"
        :disable-pagination="true"
        :show-expand="false"
        style="width: 500px;"
        v-if="!job.running"
        :key="`stats_${refreshStat}`"
    >
    </v-data-table>

    <h5
        class="pt-5"
        v-if="!job.running && showCustomExcel"
    >Report</h5>

    <v-data-table
        :headers="columns"
        :items="data"
        dense
        :hide-default-footer="true"
        :disable-pagination="true"
        no-data-text="No data"
        :show-expand="false"
        cols="6"
        fixed-header
        v-if="showReportLines"
    >
    </v-data-table>      
    <v-card
        v-if="showTooBig || showCustomExcel"
    >
        <v-card-text class="text-h5">
            {{excelMessage}}
        </v-card-text>

        <v-card-actions>
            <v-btn
                @click="downloadReport()"
                color="primary"
                text
                :disabled="false"
                v-if="showExcelLink"
            >
            Download {{reportOutput}}</v-btn>
        </v-card-actions>
    </v-card>
    <v-card
        v-if="showError"
    >
        <v-card-text class="text-h5 error--text">
            An error has occurred when running report {{this.job.job_id}}.  Please try again or contact support.
        </v-card-text>

    </v-card>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import YcmdProgress from "@/components/ycmd-progress";
import { format, isSameYear } from 'date-fns';
  export default {
    props: {
      job: {
        default: () => {},
        type: Object
      }
    },
    data: () => ({
        refreshStat: 0,
        statistics: [],
        closeProgressFlag: 0,
        groups: []
        //showProgress: this && this.job && this.job.progress && this.job.progress.type !== 'none' ? 1 : 0
    }),
    async mounted() {
        let variables = {
            group_id: null
        }

        this.groups = await this.$store.dispatch('report/loadReferential', {query: 'phxGroups', variables: variables})
        console.log('this.groups', this.groups)

    },
    methods: {
        closeProgress() {
            setTimeout(() => {
                this.closeProgressFlag = 1
            }, 1000)
        },
        async downloadReport () {
            window.open(this.job.url, '_blank');
        },        
        formatDateTime(date) {
            let pieces = date.split('T')
            
            return `${this.formatDate(pieces[0])} ${pieces[1]}`
        },

        formatDate(date) {
            let dt = date

            if (typeof(date) === 'string') {
                dt = new Date(date.replace('-','/'))
            }

            let now = new Date()
            let formatString = 'MMM d'

            if (!isSameYear(dt, now)) {
                formatString += ', yyyy' 
            }

            return format(dt, formatString)
        },        
      formatValue(item, propDefinition) {
          let pieces
          
          if (propDefinition) {
              switch (propDefinition.data_type) {
                  case 'daterange':

                      if (item.value.indexOf('|')< 0) {
                          return item.value
                      }

                      pieces = item.value.split('|')

                      value = `${this.formatDate(pieces[0])} - ${this.formatDate(pieces[1])}`

                      return value
                  case 'datetimerange':
                      if (!item.value) {
                          return item.value
                      }

                      if (item.value.indexOf('|')< 0) {
                          return item.value
                      }

                      pieces = item.value.split('|')

                      value = `${this.formatDateTime(pieces[0])} - ${this.formatDateTime(pieces[1])}`

                      return value
                      
                  case 'GROUP_ID':

                      let group = this.groups.find(g => {
                          return g.id === item.value
                      })

                      if (group) {
                          return `${group.name} (${group.id})`
                      } else {
                        return item.value
                      }
                      break
                  case 'string':
                      let list = this.lists[propDefinition.select_list_key]
                      if (list) {
                          let valueMap = {}
                          list.values.map(v => {
                              valueMap[v.value] = v
                          })
                          let value = JSON.parse(item.value)

                          if (value) {
                              if (!Array.isArray(value)) {
                                  value = [value]
                              }
                              let formattedValue = ''
                              value.map(v => {
                                  let li = valueMap[v]

                                  if (formattedValue) {
                                      formattedValue += ', '
                                  }
                                  formattedValue += li.name
                              })
                              return formattedValue
                          }
                      }
                      
                      return item.value
                  case 'USER_IDS':
                  case 'AGENT_IDS':
                  case 'MEMBER_IDS':
                  case 'PROVIDER_IDS':
                    console.log('item', item)
                    let value = JSON.parse(item.value)

                    if (value) {
                        if (!Array.isArray(value)) {
                            value = [value]
                        }
                        let formattedValue = ''
                        value.map(v => {
                            console.log('v', v)
                            if (formattedValue) {
                                formattedValue += ', '
                            }

                            let key = `user_${v}`

                            let user = this.getReferentialByKey(key) 
                            
                            if (user) {
                                formattedValue += `${user.first_name} ${user.last_name}`
                            } else {
                                formattedValue += v
                            }
                        })
                            return formattedValue
                    }
              }
          }
          return item.value
      }
    },
    computed: {
      ...mapGetters('app', ['globalEvent']),
      ...mapGetters('report', ['getReferentialByKey']),
      reportOutput () {
        return this.job.output_type === 'csv' ? 'Comma Separated Variable (csl)' : 'Excel (xlst)'
      },
      showProgress () {
        if (this.closeProgressFlag) {
            return false
        }

        if (this.job && this.job.progress && this.job.progress.type === 'none') {
            return false
        }

        return true

      },
      showReportLines () {

        if (this.showCustomExcel) {
            return false
        }
        
        if (this.job.output === 'both' || this.job.output === 'json') {
            return true
        } else {
            return false
        }
      },
      showTooBig () {
        if (this.job.lines === null && this.job.url) {
            return true
        } else {
            return false
        }
      },
      showCustomExcel () {
        let showCustom = false
        if (this.job && this.job.report && this.job.report.definition && this.job.report.definition.reportProperties && this.job.report.definition.reportProperties.length) {
            let obj = this.job.report.definition.reportProperties.find(p => {
                return p.name == 'customExcelFormat' 
            })
            if (obj) {
                showCustom = obj.value.toLowerCase() === 'true' ? true : false
            }
        }
        return showCustom
      },
      excelMessage () {
        let msg = `The report has finished but was too large to return to preview.  In order to view the report, you must download the ${this.reportOutput} file.`
        if (this.showCustomExcel) {
            msg = `This report is only available as an excel spreadsheet.  In order to view the report, you must download the ${this.reportOutput} file.`
        }
        return msg
      },
      showExcelLink () {
        console.log('this.job', this.job)

        if (this.job && !this.job.running && this.job.output_type === 'xls' && this.job.url) {
            return true
        }
        if (this.job && !this.job.running && this.job.url) {

            let obj = this.job.report.definition.reportProperties.find(p => {
                return p.name == 'customExcelFormat' 
            })
            if (obj) {
                return obj.value.toLowerCase() === 'true' ? true : false
            }

        }

        return false
      },
      showCsvLink () {
        if (this.job && !this.job.running && this.job.output_type === 'csv' && this.job.url) {
            return true
        }
        return false
      },
      showError () {

        if (this.job && this.job.error) {
            return true
        }
        return false
      },
      propertyDefinitions () {
          let ret = {}

          if (this.job && this.job.report && this.job.report.definition && this.job.report.definition.parameters && this.job.report.definition.parameters.length) {
              this.job.report.definition.parameters.map(p => {
                  ret[p.id] = p
              })
          }
          return ret
      }, 
      properties () {
        let pdefs = this.propertyDefinitions
        let arr = []

        if (this.job && this.job.report && this.job.report.definition && this.job.report.definition.parameters && this.job.report.definition.parameters.length) {
            this.job.report.properties.map(p => {
              if (p.value) {
                let def = pdefs[p.id]
                arr.push({
                  name: def.name,
                  value: this.formatValue(p, def)
                })
              }
            })
        }
        return arr
      },
      statisticColumns () {
    
        let cols = [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    width: '50%',
                    value: 'name'
                },
                {
                    text: 'Value',
                    align: 'start',
                    sortable: false,
                    width: '50%',
                    value: 'value'
                }
        ]
        return cols
      },
      propertyColumns () {
    
        let cols = [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    width: '50%',
                    value: 'name'
                },
                {
                    text: 'Value',
                    align: 'start',
                    sortable: false,
                    width: '50%',
                    value: 'value'
                }
        ]
        return cols
      },
      columns () {
        let cols = []
        let cnum = 0

        if (this.job && this.job.columns && this.job.columns.length) {
            this.job.columns.map(c => {
                cols.push({
                    text: c.label,
                    align: 'start',
                    sortable: false,
                    width: c.width ? c.width: '200px',
                    value: `col_${cnum++}`
                })            
            })    
        } 

        return cols
      },
      data () {
        let arr = []

        if (this.job && this.job.lines && this.job.lines.length) {
            this.job.lines.map (l => {
                let obj = {}

                for (let i = 0;i<this.job.columns.length;i++) {
                    obj[`col_${i}`] = l[i]
                }
                arr.push(obj)
            })
        }
        return arr
      },
        lists () {
            let ret = {}

            if (this.job.report && this.job.report.reportDefinition && this.job.report.reportDefinition.lists && this.job.report.reportDefinition.lists.length) {
                this.job.report.reportDefinition.lists.map(p => {
                    ret[p.key] = p
                })
            }
            return ret
        },
        running () {
            if (this.job) {
                return this.job.running
            }
            return false
        }
    
    },
    components: {
        'ycmd-progress': YcmdProgress
    },
    watch: {
        
        globalEvent: {
            handler (val, oldVal) {
                console.log('globalevent', val)
                if (val.app === 'Report' && val.item === this.job.job_id) {
                    console.log('this.job', this.job)

                    if (val.command === 'finished') {
                        let arr = []
                        let fstr = 'MMM d hh:mm:ss a'
                        let start = new Date(this.job.activity_date)
                        let end = new Date(this.job.activity_date_end)
                        arr.push({
                            name: 'Report started',
                            value: format(start,fstr)
                        })
                        arr.push({
                            name: 'Report ended',
                            value: format(end,fstr)
                        })        
                        arr.push({
                            name: 'Run time',
                            value: `${(end.getTime() - start.getTime()) / 1000} (s)` 
                        })                
                        this.statistics = arr                        
                        
                        this.refreshStat += 1

                    }
                }
            },
            deep: true
        },
        
    }
  }
</script>

