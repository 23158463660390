<template>

    <div class="i-card__panel i-card__panel__right">

        <div class="i-card__panel__header br-2"
        >

            <div class="d-flex align-center flex-grow-1">

                <v-tooltip bottom
                           nudge-bottom="8"
                           nudge-right="40"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark v-on="on" @click="closeAdvancedSearch" class="ml-2 mr-2">
                            <v-icon :size="20">close</v-icon>
                        </v-btn>
                    </template>
                    <span>Close Search</span>
                </v-tooltip>

                <div class="white--text fs-14 ml-3">
                    Advanced Search
                </div>
            </div>
            <div class="d-flex align-center justify-end ml-2">

              <v-btn dark small text
                     @click="clearSearch"
                     class="ycmd-light-blue ml-2 mr-2">
                Clear
              </v-btn>

                <v-btn dark small text
                       @click="submitAdvancedSearch({ shouldRefresh: true, closePanel: true });"
                       class="ycmd-light-blue mr-1">
                    Search
                </v-btn>

            </div>
        </div>

        <div class="i-card__panel__content white br-2 pa-2"
        >

            <div class="i-card__scrollable">

                    <section-title :title="`Search Fields`"/>

                    <section-content>

                        <template v-slot:section-content>
                            <div>
                                <v-row
                                    class="mr-5"
                                >

                                    <v-col cols="12" sm="6" md="4">
                                        <div class="label-title d-flex justify-space-between align-end relative pb-2" style="margin-top: 0px;">
                                            Message Date
                                        </div>
                                        <date-range-picker
                                            v-model="advancedSearchLocal.voicemail_date"
                                            :includeCustomIcon="false"
                                            :key="`drp_${refresh}`"
                                            :datetype.sync="advancedSearch.datetype"
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">Callback Number</div>
                                        <v-text-field
                                            light dense flat outlined
                                            v-model="advancedSearchLocal.callback_phone"
                                            placeholder="Callback Number"
                                            background-color="white"
                                            v-mask = masks.phone                
                                             />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">Mailbox</div>
                                      <v-select light dense flat outlined
                                                v-model="advancedSearchLocal.mailbox"
                                                :items="mailboxes"
                                                background-color="white"
                                                />
                                    </v-col>

                                </v-row>

                                <v-row
                                    class="pa-0 ma-0"
                                >
                                    <v-col cols="12" class="pa-0 ma-0">
                                        <v-btn
                                            @click="openSchedule = true"
                                            class="white--text ycmd-light-blue"
                                        >
                                            Schedule
                                        </v-btn>
                                    </v-col>

                                </v-row>
                                <v-row
                                    class="pb-0 ma-0 pt-2"
                                >
                                    <v-col
                                        cols="12"
                                        class="pa-0 ma-0"
                                    >
                                      <div class="label-title pb-2">Schedules</div>
                                    </v-col>
                                </v-row>

                                <v-row
                                    class="pa-0 ma-0"                                    
                                >
                                    <v-col cols="12"
                                    class="pa-0 ma-0"
                                    >
                                        <schedules
                                            :schedules="messageSchedules"
                                             @deleteSchedule="deleteSchedule"
                                            height="200px"
                                        />
                                    </v-col>
                                </v-row>

                            </div>

                        </template>

                    </section-content>


            </div>

        </div>
        <schedule v-model="openSchedule" @save="saveSchedule"
            :key="`schedule_${refreshSchedule}`"
        />
        <confirmation
            v-model="confirmScheduleDelete"
            title="Delete Schedule"
            subtitle="Confirm Schedule Delete"
            text="Are you sure you want to delete the schedule?"
            @click="confirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />        

    </div>

</template>

<script>

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    import YCMDFacilitySearch from '@/components/searches/ycmd-facility';
    import YCMDProviderSearch from '@/components/searches/ycmd-provider';
    import {mapActions, mapState, mapGetters} from "vuex";
    import DateRangePicker from '@/components/datetime/date-range-picker';
    import Schedules from '@/components/schedules';
    import schedule from '@/components/modals/schedule'
    import ycmdReportScheduleCreate from '@/graphql/mutations/phoenix/ycmdReportScheduleCreate';
    import ycmdReportScheduleDelete from '@/graphql/mutations/phoenix/ycmdReportScheduleDelete';
    import Confirmation from '@/components/modals/confirmation.vue'

    export default {
        name: 'voicemailAdvancedSearch',
        components: {
            'ycmd-facility-search': YCMDFacilitySearch,
            'ycmd-provider-search': YCMDProviderSearch,
            'section-title': SectionTitle,
            'section-content': SectionContent,
            'date-range-picker': DateRangePicker,
            'schedules': Schedules,
            schedule,
            'confirmation': Confirmation,

        },
        props: {
            advancedSearch: Object,
            advancedSearchCustomFields: Array,
            clearAdvancedSearch: Function,
            submitAdvancedSearch: Function,
            status: String
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                masks: {
                    phone: '(###) ###-####'
                },
                startMenu: false,
                //start_date: null,
                endMenu: false,
                //end_date: null,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                refresh: 0,
                openSchedule: false,
                refreshSchedule:0,
                reportDefinition: {},
                scheduleDeleteId: null,
                sending: false,
                confirmScheduleDelete: false
            }
        },
        activated() {

        },
        created() {
        },
        async mounted() {
            let def = await this.$store.dispatch('report/findReportDefinition', {report_id: 'voicemail'})
            this.reportDefinition = def
        },
        computed: {
            ...mapGetters(
                'profile', ['getGroup']
            ),
            ...mapGetters('report', ['getReportDefinitions']),
            messageSchedules () {
                if (this.getReportDefinitions && this.getReportDefinitions.length) {
                    let def = this.getReportDefinitions.find(d => {
                        return d.id === 'voicemail'
                    })
                    if (def) {
                        return def.schedules
                    }
                }
                return []
            },
            legacyRoleMap () {
                let map = {}
                let roles = this.getLegacyRoles
                console.log('roles', roles)
                if (roles && roles.length) {
                    roles.map(r => {
                        map[r] = true
                    })
                }
                return map
            },
            nurseView () {
                if (this.legacyRoleMap['provider']) {
                    return false
                }
                if (this.legacyRoleMap['member']) {
                    return false
                }
                if (this.legacyRoleMap['nurse']) {
                    return true
                }

                return false

            },
            advancedSearchLocal: {
                get: function(val) {
                    return this.advancedSearch;
                },
                set: function(newValue) {
                    this.$emit('update:advancedSearch', newValue)
                }
            },
            mailboxes () {
                let arr = []
                let group = this.getGroup

                if (group && group.voicemail && group.voicemail.mailboxes && group.voicemail.mailboxes.length) {
                    group.voicemail.mailboxes.map(m => {
                        arr.push(m.name)
                    })
                }

                return arr
            },
            confirmationButtons () {
                return [
                    {caption: 'OK', disabled: this.sending},
                    {caption: 'Cancel', disabled: this.sending}
                ]
            },

        },
        watch: {
        },
        methods: {
            async saveSchedule (schedule) {
                let scheduleObject = {
                    report_id: 'voicemail',
                    schedule_repeat: schedule.repeat,
                    dow: schedule.dow,
                    dom: schedule.dom,
                    time: schedule.time,
                    parameters: [],
                    format: 'xls',
                    emails: schedule.emails
                }

                let parameters = {}
                parameters.group_id = this.getGroup.id

                let statusArray = this.status === 'complete' ? ['complete'] : ['new','left_message','sent_text','waiting', 'unavailable']
                parameters.status = statusArray.join('|')

                if (this.advancedSearchLocal.voicemail_date) {
                    parameters.voicemail_date = this.advancedSearchLocal.voicemail_date
                }

                if (this.advancedSearchLocal.callback_phone) {
                    parameters.callback_phone = this.advancedSearchLocal.callback_phone
                }

                if (this.advancedSearchLocal.mailbox) {
                    parameters.mailbox = this.advancedSearchLocal.mailbox
                }
                console.log('parameters', parameters)
                console.log('advancedSearch', this.advancedSearch)

                for (let p in parameters) {
                    scheduleObject.parameters.push({
                        id: p,
                        value: parameters[p]
                    })
                }
                let response = await this.$apollo.mutate({
                    mutation: ycmdReportScheduleCreate,
                    variables: {schedule: scheduleObject},
                    client: 'phoenixClient',
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                })

                if (response && response.data && response.data.ycmdReportScheduleCreate) {
                    await this.$store.commit('report/addSchedule',  
                    {
                        report_id: 'voicemail',
                        schedule: JSON.parse(response.data.ycmdReportScheduleCreate.data)
                    })
                    this.$toasted.success('Schedule saved');
                    this.refreshSchedule++

                } else {
                        this.$toasted.error(`An error has occurred`);
                }
            },

            deleteSchedule (schedule) {
                this.scheduleDeleteId = schedule
                this.confirmScheduleDelete = true
            },

            clearSearch() {
                this.clearAdvancedSearch();
                //this.submitAdvancedSearch({ shouldRefresh: false, closePanel: false });
            },
            closeAdvancedSearch() {
                // update on parent
                this.submitAdvancedSearch({ shouldRefresh: false, closePanel: true });
            },
            async confirmationClicked (btn) {
                if (btn.caption === 'OK') {
                    this.sending = true
                    let response = await this.$apollo.mutate({
                        mutation: ycmdReportScheduleDelete,
                        variables: {schedule_id: this.scheduleDeleteId},
                        client: 'phoenixClient',
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    })

                    console.log('response', response)
                    if (response && response.errors && response.errors.length) {
                        this.$toasted.error(`Error: ${response.errors[0].message}`);
                    } else {
                        await this.$store.commit('report/removeSchedule',  
                        {
                            report_id: 'voicemail',
                            schedule_id: this.scheduleDeleteId
                        })
                        this.$toasted.success('Schedule deleted');
                    }
                    this.sending = false
                }
                this.confirmScheduleDelete = false
            },            
        }
    }

</script>

