import gql from 'graphql-tag'

export default gql`query ycmdMessageGet(
		$message_id: ID,
		$group_id: ID,
		$folder: Folder,
		$page: Int,
		$nextToken: String,
		$limit: Int,
		$facility_id: ID,
		$facility_unit_shortcode: ID,
		$from_user_id: ID, $from_role: YcmdRole,
		$to_user_id: ID,
		$patient_type: PatientStatus,
		$confirmation_status: MessageConfirmedStatus,
		$start_date: AWSDateTime,
		$end_date: AWSDateTime,
		$output_type: OutputType,
		$message_answers: [IdValueInput],
		$exclude_folders: [Folder],
		$inbox_mode: MessageInboxMode,
		$message_date: String
	) {
	ycmdMessageGet(
		message_id: $message_id,
		group_id: $group_id,
		folder: $folder,
		page: $page,
		nextToken:$nextToken,
		limit: $limit,
		facility_id: $facility_id,
		facility_unit_shortcode: $facility_unit_shortcode,
		from_user_id: $from_user_id,
		from_role: $from_role,
		to_user_id: $to_user_id,
		patient_type: $patient_type,
		confirmation_status: $confirmation_status,
		start_date: $start_date,
		end_date: $end_date,
		output_type: $output_type,
		message_answers: $message_answers,
		exclude_folders: $exclude_folders,
		convert_times: true,
		user_mode: unlinked,
		inbox_mode: $inbox_mode,
		message_date: $message_date
	)
    {
			total_count
			messages_url
			nextToken
			messages {
				call_recordings
				callback
				date_created
				date_confirmed
				id
				from_user_id
				facility_name
				facility_unit
				last_sent
				guest_name
				patient_status
				bypass_coverage
				to_user {
					id
					username
					first_name
					last_name
					middle_initial
					group_id
					email
				}
				fields {
					name
					value
					id
					label
					formatted_value
				}
				device {
					name
				}
				from_user {
				    id
				    username
				    first_name
				    last_name
				    middle_initial
				    group_id
				    email
				}
				orig_user {
					id
					username
					first_name
					last_name
					middle_initial
					group_id
					email
				}
				forwarded_message
				forwards {
					user_id
					user_name
					date_forwarded
					forwarded_by
					forwarded_by_name
				}
				patient {
				    dob
				    first_name
				    last_name
				}
				response
				contents
		}
  }
}`
