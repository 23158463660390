<template>

  <v-container
    fluid
    class="ycmd-dark-blue-radial-gradient full-width full-height pa-2 br-2"
  >
    <v-row>
      <v-col
        cols="12"
      >  
        <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="false"
                :background-color="`#101622`"
                :color="`#637eb6`"
                :loader="`bars`">
        </loading>
      </v-col>
    </v-row>
    <v-row>
      <v-col
      >  
        <div class="fs-16 fw-500 white--text">LOGIN</div>
      </v-col>
      <v-spacer />
      <v-col>
        <v-btn dark depressed small text class="fade-2" @click="route('forgotPassword', 'next')" color="white">Forgot Password?</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >  
        <v-text-field
            dark solo dense flat
            v-model="username"
            name="input-username"
            :tabindex="1"
            background-color="ycmd-dark-blue"
            v-on:keyup.enter="loginStart"
            label="Username"/>

      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >  
        <v-text-field
            solo dark dense flat
            v-model="password"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            name="input-password"
            :tabindex="2"
            label="Password"
            background-color="ycmd-dark-blue"
            v-on:keyup.enter="loginStart"
            @click:append="showPassword = !showPassword" />
          <div v-if="errorMessage" class="pa-4 br-2 pink white--text">
            {{errorMessage}}
          </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >  
          <v-btn dark block large depressed color="ycmd-light-blue" @click="loginStart">Continue</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >  
          <v-btn dark text depressed color="white" class="fade-2 mt-1" style=""  @click="route('authFAQ', 'up')">FAQ</v-btn>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>

import {mapActions, mapGetters} from "vuex";

import Loading from 'vue-loading-overlay';

import 'vue-loading-overlay/dist/vue-loading.css';

import LOGIN_START from '../../graphql/mutations/authenticateBegin';

import { routeBasedOnRole } from '../../methods/globalMethods';
import SubscriptionService from '@/methods/subscriptionInstance'
import store from '@/store'

export default {
  name: 'login',
  data: () => ({
    isLoading: false,
    user: null,
    username: '',
    password: '',
    challengeName: '',
    session: '',
    showPassword: false,
    errorMessage: '',
    rules: {
      required: value => !!value || 'Required.',
      min: v => v && v.length >= 8 || 'Min 8 characters',
      emailMatch: () => ('The email and password you entered don\'t match')
    }
  }),
  components: {
    Loading
  },
  created() {

    // Make sure the right menu is not visible on this page
    this.closeRightMenu();
  },
  mounted() {

    console.log("IN LOGIN SCREEN....")

    // if you go to login page while still logged in bring them back to app
    routeBasedOnRole(this.route);
  },
  apollo: {
    // $client: 'publicClient',
    loginStart: {
      query: () => LOGIN_START,
      client: 'publicClient',
      variables() {
        return {
          email: this.username,
          password: this.password
        }
      },
      error(error) {
        console.log('There was an error logging in -loginStart-: ', error);
      },
      skip: true
    }
  },
  computed: {
    ...mapGetters('profile', ['getHasChat']),
  },
  methods: {
    ...mapActions('profile', ['setProfile', 'enableChatNotifications', 'setChatRoomNotifications', 'setInactivityTimeoutMinutes']),
    ...mapActions('group', ['initGroups']),
    ...mapActions('rightMenu', ['openRightMenu', 'closeRightMenu', 'enableRightMenu', 'disableRightMenu']),
    ...mapActions('menu', ['openMenu', 'closeMenu', 'toggleMenu']),
    ...mapActions('chat', ['loadRooms']),
    async loginStart() {

      this.isLoading = true;

      try {

        // save email
        //console.log("SAVING USERNAME: ", this.username);
        localStorage.setItem("username", this.username);

        const response = await this.$apollo.mutate({
          mutation: LOGIN_START,
          variables: {
            email: this.username,
            password: this.password
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
          client: 'publicClient'
        });

        //console.log('login response: ', response);

        this.isLoading = false;

        const { authenticateBegin } = response.data;

        if (authenticateBegin) {
          const { AuthenticateResult } = authenticateBegin;

          // authenticateResult
          if (AuthenticateResult) {

              this.$store.dispatch('app/init', {index: 0, itemIndex: 0}, {root: true});

              const { phoenixAuthenticate } = AuthenticateResult;
              /*
              setTimeout(() => {
                store.dispatch('rightMenu/routeRightMenu', {name: 'CHAT.rooms', transition: 'next'});
              }, 100);              
              */
              // check phoenix result
              if (phoenixAuthenticate) {

                  const {
                      token,
                      refresh_token,
                      first_name,
                      last_name,
                      user_id,
                      reference_id,
                      group_id,
                      group_name,
                      group,
                      legacy_roles,
                      roles,
                      linked_groups,
                      linked_users,
                      settings,
                      online_status
                  } = phoenixAuthenticate;
                  // save phoenix token
                  //console.log("SAVING ACCESS TOKEN: ", token);
                  localStorage.setItem("accessToken", token)

                  // save refresh token
                  // console.log("SAVING refresh TOKEN: ", refresh_token);
                  localStorage.setItem("refreshToken", refresh_token)

                  //console.log("LOGIN_ONLINE: ", online_status)
                  // set profile VueX

                  await this.setProfile({
                      firstName: first_name || 'N/A',
                      lastName: last_name || 'N/A',
                      userID: user_id,
                      referenceID: reference_id,
                      group: { id: group_id, name: group_name, voicemail: group ? group.voicemail: null, forwarding: group ? group.forwarding: null, settings: group ? group.settings: {} },
                      roles: roles,
                      legacy_roles: legacy_roles,
                      role: 'Provider',
                      avatar: null,
                      online: online_status,
                      settings: settings
                  });

                  // set groups
                  await this.initGroups({
                      myGroup: {
                          group_id: group_id,
                          group_name: group_name,
                          user_id: user_id,
                          settings: group.settings
                      },
                      groups: linked_groups || [],
                      myUser: { id: user_id,  roles: roles },
                      linkedUsers: linked_users || []
                  });

                    if (this.getHasChat) {
                      SubscriptionService.init({userID: user_id, groupID: group_id});                    

                      this.rightMenuEnabledFlag = true
                      // enable the menu
                      this.enableRightMenu();
                      this.loadRooms()
                    } else {
                        SubscriptionService.init({userID: null, groupID: group_id});                    

                        this.rightMenuEnabledFlag = false
                        this.disableRightMenu();
                    }

/*
                  // initiate the subscription
                  let chatUid = roles && roles.length && roles.find(role => role === 'chat') ? user_id: null

                  SubscriptionService.init({userID: chatUid, groupID: group_id});

                  if (roles && roles.length && roles.find(role => role === 'chat')) {
                      // enable the menu
                      if (this.$vuetify.breakpoint.mdAndUp) {
                        this.enableRightMenu();
                      }
                      else {
                        this.closeMenu();
                        this.disableRightMenu();  
                      }
                  } else {
                      this.disableRightMenu();
                  }
*/
                  if (settings) {
                      const { enable_chat_notifications, room_settings } = settings;

                      this.enableChatNotifications({ enable_chat_notifications } );

                      this.setInactivityTimeoutMinutes(settings.inactivity_timeout_minutes)

                      if (room_settings) {
                          this.setChatRoomNotifications(room_settings);
                      }
                  }
                  if (phoenixAuthenticate.authentication_status &&
                      phoenixAuthenticate.authentication_status.prompt_for_twofactor  ){
                      store.commit('app/setAuthenticationStatus', phoenixAuthenticate.authentication_status)
                      store.commit('app/setAuthenticationStatusOpen', true)
                  }
                    
              }
          }

          const { Session, ChallengeName } = authenticateBegin;

          this.$store.commit('profile/setAuthenticateBegin', {
            session: Session,
            challengeName: ChallengeName,
            username: this.username
          });

          // Check if it's a created account by admin or user
          if (authenticateBegin.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            this.route('newPass', 'next');
          }
          else if (ChallengeName === 'SMS_MFA') {

            // successful login
            this.$store.commit('profile/setPassword', {
              username: this.username,
              password: this.password
            });

            this.route('mfaCode', 'next');
          }
          else {
            routeBasedOnRole(this.route);
          }
        }
        else if (response.errors && response.errors.length > 0) {
          this.errorMessage = response.errors[0].message;
        }
        else {
          // data did not come back
          console.log('loginStart data did not come back: ', response);
        }
      }
      catch (e) {
        this.isLoading = false;
        console.log('CAUGHT: There was an error logging in *loginStart* : ', e);
      }
    },
    route(name, direction) {

      this.$router.push({
        name: name, params: {
          transition: direction
        }
      }).catch(err => { console.log('router error: ', err) });
    }
  }
}

</script>
