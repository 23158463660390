import gql from 'graphql-tag'

export default gql`mutation ycmdContactAdd(
		$specialty: String,
		$first_name: String,
		$notes: String,
		$phone_numbers: [PhonenumberInput],
		$company: String,
		$addresses: [AddressInput],
		$last_name: String,
		$email_addresses: [EmailAddressInput]
	) {
		ycmdContactAdd(specialty: $specialty, first_name: $first_name, notes: $notes, phone_numbers: $phone_numbers, company: $company, addresses: $addresses, last_name: $last_name, email_addresses: $email_addresses) {
			... on ContactCustom {
				type
			}
		}
}`
