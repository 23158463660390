<template>

  <div class="view">

    <v-overlay :value="isLoading" :absolute="true" color="#2389e8" opacity="0.3">
      <div class="d-flex align-center justify-center">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </v-overlay>

    <i-card :viewIndex="card.index"
            :viewCount="1"
            :leftPanel="card.panels.left"
            :rightPanel="card.panels.right"
            :topPanel="card.panels.top"
            :bottomPanel="card.panels.bottom"
            class="i-card mr-2 br-2">

      <div class="i-card__main pa-2 pr-0 pt-0">

        <div class="i-card__main__header pr-2">

          <div class="d-flex align-center flex-grow-1">

            <v-tabs dark
                    background-color="transparent"
                    v-model="headerTabs">

              <v-tabs-slider color="ycmd-green"></v-tabs-slider>

              <v-tab href="#tab-group">Group</v-tab>
              <v-tab href="#tab-office">Office</v-tab>
            </v-tabs>

            <v-spacer/>

            <v-tooltip bottom
                       nudge-bottom="0"
                       nudge-right="32"
                       color="ycmd-black"
                       :disabled="$vuetify.breakpoint.xsOnly"
                       content-class="fade-0 pa-4 pt-2 pb-2">
              <template v-slot:activator="{ on }">
                <v-btn dark text small
                       @click="onSavePage"
                       v-on="on"
                       class="mr-2 ycmd-light-blue">
                  Save
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>

          </div>

        </div>

        <div class="i-card__main__content white pa-2 br-2">

          <refresh-init-content v-if="!isLoading && !initDataLoaded" :refreshHandler="getGroupData"/>

          <div v-else class="i-card__scrollable">

            <div class="list-container">

              <v-tabs-items v-model="headerTabs">

                <v-tab-item value="tab-group">

                  <edit-group-page ref="editGroupPageRef"
                                   :groupData.sync="groupData"
                                   :groupSettings.sync="groupSettings"
                                   :savedTimestamp="savedTimestamp">

                  </edit-group-page>

                </v-tab-item>

                <v-tab-item value="tab-office">

                  <edit-office-page ref="editOfficePageRef" :officeData.sync="officeData">

                  </edit-office-page>

                </v-tab-item>

              </v-tabs-items>

            </div>

          </div>

        </div>

      </div>

    </i-card>

  </div>

</template>

<script>

import {mapActions, mapState, mapGetters} from "vuex";
import {format, parseISO} from 'date-fns';
import {truncateText} from '@/methods/utils';

import {formatPhoneNumber} from '@/methods/globalMethods';

import GET_GROUP_DATA from '@/graphql/queries/phoenix/ycmdGroupGet';
import UPDATE_GROUP_DATA from '@/graphql/mutations/phoenix/ycmdGroupUpdate';

import EditGroup from './editGroup/page.vue';
import EditOffice from './editOffice/page.vue';
import RefreshInitContent from '@/components/loader/refresh-init';

export default {
  name: 'adminGroupList',
  components: {
    'edit-group-page': EditGroup,
    'edit-office-page': EditOffice,
    'refresh-init-content': RefreshInitContent
  },
  apollo: {
    $client: 'phoenixClient'
  },
  props: {
    changeView: Function
  },
  data() {
    return {
      format,
      parseISO,
      truncateText,
      formatPhoneNumber,
      headerTabs: null,
      isLoading: false,
      initDataLoaded: false,
      card: {
        index: 0,
        panels: {left: false, right: false, top: false, bottom: false}
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      },
      savedTimestamp: null,
      groupData: {
        validGroupDataForm: false,

        practiceName: null,
        otherName: null,
        practiceType: null,
        entityType: null,
        timeZoneType: null,
        facilities: null,
        greeting: null,

        mailingAddress: null,
        mailingAddressCont: null,
        mailingCity: null,
        mailingState: null,
        mailingZipCode: null,
      },
      groupSettings: {
        transfer_patient: false,
        force_app_lock: false,
        show_provider_phone_numbers: false,
        patient_tags: null,
        inactivity_timeout_minutes: 60,
        authentication_type: 'standard',
        authentication_grace_days: 7,
        chat_allow_same_participant_rooms: false
      },
      officeData: {
        validOfficeDataForm: false,

        officeAddress: null,
        officeAddressCont: null,
        officeCity: null,
        officeState: null,
        officeZipCode: null,

        officePhone: null,
        officeFax: null,
        officeWebsite: null,

        officeHours: [
          {
            close: null,
            day: "1",
            open: null,
          },
          {
            close: null,
            day: "2",
            open: null,
          },
          {
            close: null,
            day: "3",
            open: null,
          },
          {
            close: null,
            day: "4",
            open: null,
          },
          {
            close: null,
            day: "5",
            open: null,
          },
          {
            close: null,
            day: "6",
            open: null,
          },
          {
            close: null,
            day: "7",
            open: null,
          },
        ],
        lunchPhone: null,
      },
    }
  },
  mounted() {
    this.getGroupData();
  },
  methods: {
    ...mapActions('menuRight', ['openMenuRight', 'closeMenuRight', 'toggleMenuRight']),
    onSearchClick(e, value) {

      console.log(value);
    },
    onSearchChange(e, value) {

      console.log(value);
    },
    slide(direction) {
      this.card.panels[direction] = !this.card.panels[direction];
    },
    route(name, transition) {

      this.$router.push({
        name: name, params: {
          transition: transition
        }
      }).catch(err => {
        console.log('router error: ', err)
      });
    },
    onSavePage() {
      console.log(this.headerTabs);
      switch (this.headerTabs) {
        case 'tab-group':
          // this.$refs.editGroupPageRef.$refs.validGroupDataForm.validate();
          // console.log(this.groupData.validGroupDataForm);
          // if (this.groupData.validGroupDataForm) {
          //     this.updateGroup();
          // }
          this.updateGroup();
          break;
        case 'tab-office':
          this.updateOffice();
          break;
      }
    },
    async getGroupData() {
      this.isLoading = true;

      try {
        const response = await this.$apollo.query({
          query: GET_GROUP_DATA,
          variables: {
            accountType: 'all'
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });
        this.isLoading = false;
        console.log('response: ', response);

        // update
        if (response.data && response.data.ycmdGroupGet) {
          // set loaded
          this.initDataLoaded = true;

          // group data
          this.groupData.practiceName = response.data.ycmdGroupGet.name || '';
          this.groupData.otherName = response.data.ycmdGroupGet.alt_name || '';
          this.groupData.practiceType = response.data.ycmdGroupGet.group_type || null;
          this.groupData.timeZoneType = response.data.ycmdGroupGet.time_zone || null;
          this.groupData.entityType = response.data.ycmdGroupGet.entity_type || null;
          this.groupData.facilities = response.data.ycmdGroupGet.facilities ? response.data.ycmdGroupGet.facilities.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : [];
          this.groupData.greeting = response.data.ycmdGroupGet.greeting || '';

          if (response.data.ycmdGroupGet.mail_address) {
            this.groupData.mailingAddress = response.data.ycmdGroupGet.mail_address.street || '';
            this.groupData.mailingAddressCont = response.data.ycmdGroupGet.mail_address.street2 || '';
            this.groupData.mailingCity = response.data.ycmdGroupGet.mail_address.city || '';
            this.groupData.mailingState = response.data.ycmdGroupGet.mail_address.state || '';
            this.groupData.mailingZipCode = response.data.ycmdGroupGet.mail_address.zip_code || '';
          }
          // group settings
          if (response.data.ycmdGroupGet.settings) {
            this.groupSettings.transfer_patient = Boolean(response.data.ycmdGroupGet.settings.transfer_patient);
            this.groupSettings.force_app_lock = Boolean(response.data.ycmdGroupGet.settings.force_app_lock);
            this.groupSettings.show_provider_phone_numbers = Boolean(response.data.ycmdGroupGet.settings.show_provider_phone_numbers);
            this.groupSettings.patient_tags = response.data.ycmdGroupGet.patient_tags || [];
            this.groupSettings.inactivity_timeout_minutes = response.data.ycmdGroupGet.settings.inactivity_timeout_minutes
            this.groupSettings.authentication_type = response.data.ycmdGroupGet.settings.authentication_type
            this.groupSettings.authentication_grace_days = response.data.ycmdGroupGet.settings.authentication_grace_days
            this.groupSettings.chat_allow_same_participant_rooms = response.data.ycmdGroupGet.settings.chat_allow_same_participant_rooms
          }

          // office data
          if (response.data.ycmdGroupGet.address) {
            this.officeData.officeAddress = response.data.ycmdGroupGet.address.street || '';
            this.officeData.officeAddressCont = response.data.ycmdGroupGet.address.street2 || '';
            this.officeData.officeCity = response.data.ycmdGroupGet.address.city || '';
            this.officeData.officeState = response.data.ycmdGroupGet.address.state || '';
            this.officeData.officeZipCode = response.data.ycmdGroupGet.address.zip_code || '';
          }
          this.officeData.officePhone = response.data.ycmdGroupGet.phone ? formatPhoneNumber(response.data.ycmdGroupGet.phone) : '';
          this.officeData.officeWebsite = response.data.ycmdGroupGet.website || '';
          this.officeData.officeFax = response.data.ycmdGroupGet.fax ? formatPhoneNumber(response.data.ycmdGroupGet.fax) : '';
          this.officeData.lunchPhone = response.data.ycmdGroupGet.lunch_phone ? formatPhoneNumber(response.data.ycmdGroupGet.lunch_phone) : '';
          this.officeData.officeHours = response.data.ycmdGroupGet.hours.map((day) => {
            return {
              ...day,
              enabled: (day.open || day.close) ? true : false
            }
          }) || [];
        } else {

        }
      } catch (e) {
        this.isLoading = false;
        // defaultErrorHandler(e);
        console.log('Catch error: ', e);
      }
    },
    async updateGroup() {
      this.isLoading = true;
      console.log('timeZoneType type: ', this.groupData.timeZoneType);
      try {

        let group = {
            mail_address: {
              street: this.groupData.mailingAddress,
              street2: this.groupData.mailingAddressCont,
              city: this.groupData.mailingCity,
              state: this.groupData.mailingState,
              zip_code: this.groupData.mailingZipCode
            },
            name: this.groupData.practiceName,
            alt_name: this.groupData.otherName,
            group_type: this.groupData.practiceType,
            entity_type: this.groupData.entityType,
            time_zone: this.groupData.timeZoneType,
            greeting: this.groupData.greeting,
            facilities: this.groupData.facilities.map((facility) => facility.id),

            patient_tags: this.groupSettings.patient_tags.map((tag) => {
              return {
                label: tag.label,
                color: tag.color,
                id: tag.id
              }
            }),
            settings: {
              transfer_patient: Boolean(this.groupSettings.transfer_patient),
              force_app_lock: Boolean(this.groupSettings.force_app_lock),
              show_provider_phone_numbers: Boolean(this.groupSettings.show_provider_phone_numbers),
              inactivity_timeout_minutes: this.groupSettings.inactivity_timeout_minutes,
              authentication_type: this.groupSettings.authentication_type,
              authentication_grace_days: this.groupSettings.authentication_grace_days,
              chat_allow_same_participant_rooms: this.groupSettings.chat_allow_same_participant_rooms
            }
          }
        const response = await this.$apollo.mutate({
          mutation: UPDATE_GROUP_DATA,
          variables: group,
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });

        this.isLoading = false;
        console.log('response: ', response);

        // update
        if (response.data && response.data.ycmdGroupUpdate) {

          this.$store.commit('profile/setGroup', group)
          this.$store.commit('group/setMyGroup', group)
          this.$toasted.success(`Successfully updated group information!`);
          this.savedTimestamp = Date.now();
        } else if (response.errors && response.errors.length > 0) {
          if (response.errors[0].message) {
            this.$toasted.error(`There was an error updating group - ${response.errors[0].message}`);
          } else {
            this.$toasted.error(`There was an error updating group`);
          }
        } else {
          // data did not come back
          console.log('ycmdGroupUpdate data did not come back: ', response);
          this.$toasted.error(`There was an error.`);
        }
      } catch (e) {
        this.isLoading = false;
        // defaultErrorHandler(e);
        console.log('Catch error: ', e);
      }
    },
    async updateOffice() {
      this.isLoading = true;

      try {
        const response = await this.$apollo.mutate({
          mutation: UPDATE_GROUP_DATA,
          variables: {
            address: {
              street: this.officeData.officeAddress,
              street2: this.officeData.officeAddressCont,
              city: this.officeData.officeCity,
              state: this.officeData.officeState,
              zip_code: this.officeData.officeZipCode
            },
            phone: this.officeData.officePhone,
            website: this.officeData.officeWebsite,
            fax: this.officeData.officeFax,

            hours: this.officeData.officeHours.map((day) => {
              return {
                day: day.day,
                open: day.open,
                close: day.close
              }
            }),
            lunch_phone: this.officeData.lunchPhone
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });
        this.isLoading = false;
        console.log('response: ', response);

        // update
        if (response.data && response.data.ycmdGroupUpdate) {
          this.$toasted.success(`Successfully updated group information!`);
        } else if (response.errors && response.errors.length > 0) {
          if (response.errors[0].message) {
            this.$toasted.error(`There was an error updating office info - ${response.errors[0].message}`);
          } else {
            this.$toasted.error(`There was an error updating office info...`);
          }
        } else {
          // data did not come back
          console.log('ycmdGroupUpdate data did not come back: ', response);
          this.$toasted.error(`There was an error.`);
        }
      } catch (e) {
        this.isLoading = false;
        // defaultErrorHandler(e);
        console.log('Catch error: ', e);
      }
    }
  },
  watch: {
    getUserID(value) {
      this.getGroupData();
    }
  },
  computed: {
    ...mapState({
      isMenuOpen: state => state.menu.open,
      isMenuRightOpen: state => state.menuRight.open,
      appItemIndex: state => state.app.itemIndex
    }),
    ...mapGetters(
        'profile', ['getUserID']
    )
  }
}

</script>

<style scoped>

.tab {
  color: white !important;
}

.tabs-holder {
  width: 270px;
  margin-bottom: -8px;
  margin-left: -4px;
}

.tabs--active {
  background-color: white;
  color: #167DD7 !important;
}

.message__section {
  height: 200px;
}

.list-item__row {
  width: 100%;
  padding-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.list-item__row--even {
  background-color: #F0F9FF;
}

.list-item__row--odd {

}

@media (max-width: 600px) {
  .list-container {
    font-size: 10px;
    padding: 0 0 8px 0;
  }
}

/* Small Breakpoint */
@media (min-width: 600px) {

}

/* Medium Breakpoint */
@media (min-width: 960px) {
  .list-item__column {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .label-value {
    padding-bottom: 0;
  }
}

/* Large Breakpoint */
@media (min-width: 1264px) {

}

/* XLarge Breakpoint */
@media (min-width: 1904px) {

}

</style>
