<template>

  <div class="">

    <v-form
        ref="editGroupInfoForm"
        v-model="groupSettingsLocal.validGroupDataForm"
        class="">

      <!-- Title first row -->
      <section-title :title="`Group Info`"/>

      <section-content>

        <template v-slot:section-content>

          <v-row class="full-width">

            <v-col cols="12" sm="6" md="6">
              <div class="label-title">Name of Practice</div>
              <v-text-field light solo dense flat outlined
                            :rules="[rules.required]"
                            v-model="groupDataLocal.practiceName"/>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <div class="label-title">Other Entity Name / DBA</div>
              <v-text-field light solo dense flat outlined
                            :rules="[rules.required]"
                            v-model="groupDataLocal.otherName"/>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="label-title">Type of Practice</div>
              <v-select light dense flat outlined
                        v-model="groupDataLocal.practiceType"
                        :rules="[rules.required]"
                        :items="practiceTypes"
                        item-text="name"
                        item-value="value"
                        background-color="white"/>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="label-title">Type of Entity</div>
              <v-select light dense flat outlined
                        v-model="groupDataLocal.entityType"
                        :rules="[rules.required]"
                        :items="entityTypes"
                        item-text="name"
                        item-value="value"
                        background-color="white"/>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="label-title">Time Zone</div>
              <v-select light dense flat outlined
                        v-model="groupDataLocal.timeZoneType"
                        :rules="[rules.required]"
                        :items="timeZoneTypes"
                        item-text="name"
                        item-value="value"
                        background-color="white"/>
            </v-col>

          </v-row>

          <v-row class="full-width">

            <v-col cols="12" sm="12" md="12" lg="12">
              <div class="label-title">Greeting</div>
              <v-textarea
                  light dense flat outlined
                  class="small-input"
                  auto-grow
                  v-model="groupDataLocal.greeting"
                  rows="3"
                  background-color="white"/>
            </v-col>

          </v-row>

        </template>

      </section-content>

      <!-- Title 2nd row -->
      <section-title :title="`Group Facilities`">
      </section-title>

      <section-content>

        <template v-slot:section-content>

          <v-row class="d-flex justify-center">
            <div v-if="allFacilities === null && !isLoadingFacilities">
              <v-tooltip bottom
                         nudge-bottom="10"
                         color="ycmd-black"
                         content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark v-on="on" @click="getFacilities()" color="green" class="ma-4">
                    <v-icon :size="36">refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh Facilities</span>
              </v-tooltip>
            </div>
            <v-btn v-else-if="isLoadingFacilities" :loading="true" class="ma-6" icon light small rounded></v-btn>
            <v-row v-else class="ma-0">
              <v-col cols="12" sm="12" md="12" lg="12" class="mb-2">
                <DualListBox
                    :source="allFacilitiesFiltered"
                    :destination="groupDataLocal.facilities"
                    label="name"
                    :titleLeft="`Available Facilities ${ dirty.availableFacilities ? '<span class=&quot;red--text&quot;>(Not Saved)</span>' : '' }`"
                    :titleRight="`Selected Facilities ${ dirty.selectedFacilities ? '<span class=&quot;red--text&quot;>(Not Saved)</span>' : '' }`"
                    @onChangeList="onChangeList"
                />
              </v-col>
            </v-row>
          </v-row>

        </template>

      </section-content>

      <!-- Title 3rdd row -->
      <section-title :title="`Contact Information`"/>

      <section-content>

        <template v-slot:section-content>

          <v-row class="full-width mt-4">

            <v-col cols="12" sm="12" md="6">
              <div class="label-title">Mailing Address</div>
              <v-text-field light solo dense flat outlined
                            :rules="[rules.required]"
                            v-model="groupDataLocal.mailingAddress"/>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="label-title">Address Cont..</div>
              <v-text-field light solo dense flat outlined
                            v-model="groupDataLocal.mailingAddressCont"/>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="label-title">City</div>
              <v-text-field light solo dense flat outlined
                            :rules="[rules.required]"
                            v-model="groupDataLocal.mailingCity"/>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <div class="label-title">State</div>
                <v-autocomplete
                    light dense flat outlined solo
                    :rules="[rules.required]"
                    v-model="groupDataLocal.mailingState"
                    :items="states"
                    label="Select State"
                    item-value="value"
                    item-text="name"
                    background-color="white"
                    clearable
                    hide-selected
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="label-title">Zip Code</div>
              <v-text-field light solo dense flat outlined
                            :rules="[rules.required]"
                            v-model="groupDataLocal.mailingZipCode"/>
            </v-col>

          </v-row>

        </template>

      </section-content>

      <!-- Title 4th row -->
      <section-title :title="`General Settings`"/>

      <section-content>

        <template v-slot:section-content>

          <v-row>
            <v-col cols="12" sm="6" md="3" lg="3" class="mb-2">
              <div class="label-title">Enable Patient Transfer feature</div>
              <div class="d-flex">
                <v-switch
                    class="flex-grow-0"
                    hide-details
                    v-model="groupSettingsLocal.transfer_patient"
                    :label="`${groupSettingsLocal.transfer_patient ? 'Enabled' : 'Disabled'}`"
                ></v-switch>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" class="mb-2">
              <div class="label-title">Require lock screen for mobile app users</div>
              <div class="d-flex">
                <v-switch
                    class="flex-grow-0"
                    hide-details
                    v-model="groupSettingsLocal.force_app_lock"
                    :label="`${groupSettingsLocal.force_app_lock ? 'Enabled' : 'Disabled'}`"
                ></v-switch>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" class="mb-2">
              <div class="label-title">Share providers' phone numbers with other groups</div>
              <div class="d-flex">
                <v-switch
                    class="flex-grow-0"
                    hide-details
                    v-model="groupSettingsLocal.show_provider_phone_numbers"
                    :label="`${groupSettingsLocal.show_provider_phone_numbers ? 'Enabled' : 'Disabled'}`"
                ></v-switch>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" class="mb-2">
              <div class="label-title">Allow multiple chat rooms with same participants</div>
              <div class="d-flex">
                <v-switch
                    class="flex-grow-0"
                    hide-details
                    v-model="groupSettingsLocal.chat_allow_same_participant_rooms"
                    :label="`${groupSettingsLocal.chat_allow_same_participant_rooms ? 'Enabled' : 'Disabled'}`"
                ></v-switch>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4" class="mb-2">
              <div class="label-title">Inactivity Timeout (minutes)</div>
              <v-text-field light solo dense flat outlined
                            :rules="[rules.requiredString]"
                            v-model="groupSettingsLocal.inactivity_timeout_minutes"/>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4">
              <div class="label-title">Authentication Type</div>
              <v-select light dense flat outlined
                        v-model="groupSettingsLocal.authentication_type"
                        :rules="[rules.required]"
                        :items="authenticationTypes"
                        item-text="name"
                        item-value="value"
                        background-color="white"/>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" class="mb-2">
              <div class="label-title">Two Factor Grace Days</div>
              <v-text-field light solo dense flat outlined
                            :rules="[rules.required]"
                            v-model="groupSettingsLocal.authentication_grace_days"
                            :disabled="groupSettingsLocal.authentication_type !== 'twofactor'"
                            />
            </v-col>
          </v-row>


        </template>

      </section-content>

      <!-- Title 4th row -->
      <section-title :title="`Patient Tags`">
        <template v-slot:end-content>
          <v-btn @click="onAddTag" small depressed dark color="ycmd-green">Add</v-btn>
        </template>
      </section-title>

      <section-content v-if="groupSettingsLocal.patient_tags && groupSettingsLocal.patient_tags.length">

        <template v-slot:section-content>

          <v-row>
            <v-col v-for="(tag, i) in groupSettingsLocal.patient_tags" :key="`tag_${i}`" cols="6" sm="6" md="4" lg="4"
                   class="mb-2">
              <div v-if="tag.isEditable" class="d-flex" style="margin-bottom: -8px; margin-top: -2px;">
                <v-text-field class="small-text-field" style="" hide-details light solo dense flat outlined height="20"
                              :ref="`tag_ref_${i}`"
                              v-model="tag.label"
                              @blur="setTagEditOff(tag, i)"/>
                <!-- <v-btn class="" @click="() => { $set(tag, 'isEditable', false); }" icon light depressed x-small><v-icon :size="16">save</v-icon></v-btn> -->
              </div>
              <div v-else class="label-title d-flex">
                {{ tag.label }}
                <v-btn class="ml-2" style="padding-bottom: 5px;" @click="setTagEditOn(tag, i)" icon light depressed
                       x-small>
                  <v-icon :size="16">edit</v-icon>
                </v-btn>
                <v-btn style="padding-bottom: 5px;" @click="removeTag(tag, i)" icon light depressed x-small>
                  <v-icon :size="16">delete</v-icon>
                </v-btn>
              </div>
              <div class="d-flex">
                <v-btn depressed @click="onPressColor(tag)" :color="tag.color" class="full-width" style="height: 30px"/>
              </div>
            </v-col>
          </v-row>

        </template>

      </section-content>

    </v-form>

    <v-dialog eager light v-model="dialog" max-width="300">
      <div class="d-flex justify-center ycmd-dark-blue-radial-gradient">
        <v-color-picker
            flat
            v-model="currentSelectedTag.color"
            mode="hexa"
            :swatches="swatches"
            show-swatches
        ></v-color-picker>
      </div>
    </v-dialog>

  </div>

</template>

<script>

import {mapActions, mapState} from "vuex";
import {format, parseISO} from 'date-fns';
import InfiniteLoading from 'vue-infinite-loading';
import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
import {refreshHandler} from '@/graphql/handler/refreshHandler';
import {practiceTypes, timeZoneTypes, entityTypes, states} from '@/data/groupApp/dataTypes';

import GET_FACILITIES from '@/graphql/queries/phoenix/ycmdFacilitySearch';

import SectionTitle from '@/components/sections/section-title';
import SectionContent from '@/components/sections/section-content';
import DualListBox from "@/components/dualList/dual-list";


export default {
  name: 'adminEditGroup',
  components: {
    'section-title': SectionTitle,
    'section-content': SectionContent,
    DualListBox,
    InfiniteLoading
  },
  apollo: {
    $client: 'phoenixClient'
  },
  props: {
    groupData: Object,
    groupSettings: Object,
    savedTimestamp: Number
  },
  data() {
    return {
      format,
      parseISO,
      practiceTypes,
      timeZoneTypes,
      entityTypes,
      states,
      dialog: false,
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],
      swatchesPicker: ['#FF0000', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#AA0000', '#AAAA00', '#00AA00', '#00AAAA', '#0000AA', '#550000', '#555500', '#005500', '#005555', '#000055'],
      currentSelectedTag: {
        color: '#FF0000',
        id: null
      },
      card: {
        index: 0,
        panels: {left: false, right: false, top: false, bottom: false}
      },
      rules: {
        required: value => !!value || 'Required.',
        requiredString: (value) => (value+'').length ? true : false || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      },
      colorSelected: '#F44336',
      isLoadingFacilities: false,
      openedColorPicker: false,
      allFacilities: null,
      lastSavedTimestamp: null,
      dirty: {
        availableFacilities: false,
        selectedFacilities: false
      },
      authenticationTypes: [
        {name: 'Standard', value: 'standard'},
        {name: 'Two Factor', value: 'twofactor'},
      ]
    }
  },
  created() {
  },
  mounted() {
    this.getFacilities();
  },
  activated() {
  },
  watch: {
    savedTimestamp (timestamp) {

      if (timestamp !== this.lastSavedTimestamp) {
        this.dirty.availableFacilities = false;
        this.dirty.selectedFacilities = false;
      }
    }
  },
  methods: {
    ...mapActions('menuRight', ['openMenuRight', 'closeMenuRight', 'toggleMenuRight']),
    changeView(view, params) {

      this.$emit('onChangeView', {view: view, params: params});
    },
    slide(direction) {
      this.card.panels[direction] = !this.card.panels[direction];
    },
    route(name, transition) {

      this.$router.push({
        name: name, params: {
          transition: transition
        }
      }).catch(err => {
        console.log('router error: ', err)
      });
    },
    onChangeList: function ({source, destination, moved = false}) {
      this.allFacilitiesFiltered = source;
      this.groupDataLocal.facilities = destination;
      this.onChangeGroupFacilities();

      if (moved) {
        this.dirty.availableFacilities = true;
        this.dirty.selectedFacilities = true;
      }
    },
    onChangeGroupFacilities() {
      this.allFacilitiesFiltered = this.allFacilities.sort((a, b) => (a.name.trim() > b.name.trim()) ? 1 : ((b.name.trim() > a.name.trim()) ? -1 : 0));
    },
    setTagEditOn(tag, i) {
      this.$set(tag, 'isEditable', true);
      this.$nextTick(() => {
        this.$refs['tag_ref_' + i][0].focus();
      });
    },
    removeTag(tag, i) {
      this.groupSettingsLocal.patient_tags.splice(i, 1);
    },
    setTagEditOff(tag, i) {
      this.$set(tag, 'isEditable', false);
    },
    onAddTag() {
      if (this.groupSettingsLocal.patient_tags) {
        this.groupSettingsLocal.patient_tags.push({
          label: `Tag ${this.groupSettingsLocal.patient_tags.length || 'New'}`,
          color: this.groupSettingsLocal.patient_tags.length ? this.swatchesPicker[this.groupSettingsLocal.patient_tags.length % this.swatchesPicker.length] : this.swatchesPicker[0],
          id: null
        })
      } else {
        this.groupSettingsLocal.patient_tags = [];
      }
    },
    onPressColor(tag) {
      this.currentSelectedTag = tag;
      this.dialog = true;
    },
    async getFacilities(val) {
        this.isLoadingFacilities = true;
        try {
            const response = await this.$apollo.query({
                client: 'phoenixClient',
                query: GET_FACILITIES,
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
            });
            this.isLoadingFacilities = false;
            console.log('ycmd facilities response: ', response);
            const { ycmdFacilitySearch } = response.data;
            if (ycmdFacilitySearch && ycmdFacilitySearch.length) {
                this.allFacilities = ycmdFacilitySearch;
                this.onChangeGroupFacilities();
                this.filterFacilities();
            } else {
                // data did not come back
                this.allFacilities = null;
            }
        } catch (e) {
            this.isLoadingFacilities = false;
            // defaultErrorHandler(e);
        }
    }
  },
  computed: {
    ...mapState({
      isMenuOpen: state => state.menu.open,
      isMenuRightOpen: state => state.menuRight.open
    }),
    allFacilitiesFiltered: {
      get: function () {
        if (this.allFacilities && this.allFacilities.length && this.groupDataLocal.facilities && this.groupDataLocal.facilities.length) {
          return (
              this.allFacilities.filter((facility) => {
                for (let selectedFacility in this.groupDataLocal.facilities) {
                  const currentSelectedFacility = this.groupDataLocal.facilities[selectedFacility];
                  if (facility.id === currentSelectedFacility.id) return false;
                }
                return true;
              })
          )
        } else {
          return this.allFacilities;
        }
      },
      set: function (newValue) {
        this.allFacilities = newValue;
      }
    },
    groupDataLocal: {
      get: function () {
        return this.groupData;
      },
      set: function (newValue) {
        this.$emit('update:groupData', newValue)
      }
    },
    groupSettingsLocal: {
      get: function () {
        return this.groupSettings;
      },
      set: function (newValue) {
        this.$emit('update:groupSettings', newValue)
      }
    }
  }
}

</script>

<style scoped>

.message__section {
  height: 200px;
}

.list-item__row {
  width: 100%;
  padding-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 600px) {
  .list-container {
    font-size: 10px;
    padding: 0 0 8px 0;
  }
}

/* Small Breakpoint */
@media (min-width: 600px) {

}

/* Medium Breakpoint */
@media (min-width: 960px) {
  .list-item__column {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .label-value {
    padding-bottom: 0;
  }
}

/* Large Breakpoint */
@media (min-width: 1264px) {

}

/* XLarge Breakpoint */
@media (min-width: 1904px) {

}

</style>
