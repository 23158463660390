<template>

    <div class="view">

        <v-overlay :value="isLoading" :absolute="true" color="#2389e8" opacity="0.3">
            <div class="d-flex align-center justify-center" >
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </v-overlay>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card mr-2 br-2">

            <div class="i-card__main pb-2">

                <div class="i-card__main__header">

                    <transition name="fade-in-transition" mode="out-in">

                        <communication-header :toggleMenuRight="toggleMenuRight" class="pa-2 pr-0">

                            <template v-slot:default>


                                <v-tooltip bottom
                                           nudge-bottom="0"
                                           nudge-right="40"
                                           color="ycmd-black"
                                           :disabled="$vuetify.breakpoint.xsOnly"
                                           content-class="fade-0 pa-4 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark small rounded
                                               @click="card.panels.bottom = true"
                                               v-on="on"
                                               class="ml-3 mr-4 ycmd-green">
                                            <v-icon :size="16">add</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add Custom Contact</span>
                                </v-tooltip>
                                <v-tooltip bottom
                                           nudge-bottom="0"
                                           nudge-right="40"
                                           color="ycmd-black"
                                           :disabled="$vuetify.breakpoint.xsOnly"
                                           content-class="fade-0 pa-4 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark small rounded
                                               :disabled="isLoadingContacts"
                                               @click="searchFavorite = !searchFavorite"
                                               v-on="on"
                                               class="ml-2 mr-4">
                                            <v-icon :size="28" :color="`${searchFavorite ? '#ffcc00' : 'grey'}`">star</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ searchFavorite ? `Don't Show favorites` : 'Show Favorites' }}</span>
                                </v-tooltip>
                                <v-select hide-details dark
                                          v-model="contactFilterSelected"
                                          :disabled="isLoadingContacts"
                                          :items="contactFilters"
                                          item-text="name"
                                          item-value="value"
                                          style="max-width: 120px; margin-top: -10px;" />

                            </template>

                            <template v-slot:actions>

                                <v-tooltip bottom
                                           nudge-bottom="0"
                                           nudge-right="40"
                                           color="ycmd-black"
                                           :disabled="$vuetify.breakpoint.xsOnly"
                                           content-class="fade-0 pa-4 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark rounded
                                               :disabled="isLoadingContacts"
                                               @click="refreshData"
                                               v-on="on"
                                               class="">
                                            <v-icon :size="22" color="">refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refresh</span>
                                </v-tooltip>

                                <input-search @keyup.enter.native="submitSearch"
                                                      :disabled="isLoadingContacts"
                                                      :hasAdvancedSearch="false"
                                                      :onSearchClick="onSearchClick"
                                                      :openRightPanel="_ => card.panels.right = true"
                                                      :searchValue.sync="searchValue"/>

                            </template>

                        </communication-header>

                    </transition>

                </div>

                <div class="i-card__main__content d-flex br-2 overflow-none">

                    <sub-header-content v-if="items.length || true">

                        <template v-slot:header-start>

                            <v-row no-gutters>
                                <v-col cols="6" sm="6" md="4" lg="4">
                                    <div class="fixed-header__column--group fixed-header__text">Type
                                        <v-btn icon color="ycmd-green" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" lg="4">
                                    <div class="fixed-header__column--group fixed-header__text">Name
                                        <v-btn icon color="ycmd-green" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Spacing for list buttons -->
                            <div class="" style="width: 65%;">&nbsp;</div>
                            <div class="" style="width: 90px;">&nbsp;</div>

                        </template>

                        <template v-slot:header-end>

                            <counter :count="currentItems.toString()" :total="totalItems.toString()"/>

                        </template>

                    </sub-header-content>

                    <div class="d-flex flex-row flex-grow-1 full-width full-height overflow-none">

                        <div class="scrollable d-flex flex-column flex-grow-0 mt-1" style="width: 35%">

                            <v-container fluid v-for="(item, i) in items" @click="onPressContact(item)" :key="`item_${i}`" :class="`selectable list-item flex-shrink-0 pa-3 ${item && selectedContact && item.id === selectedContact.id ? 'list-item--highlighted' : '' }`">

                                <div class="d-flex flex-grow-1">

                                    <div class="d-flex flex-grow-1 flex-column">

                                        <provider-list-item
                                            v-if="item.type === 'provider'"
                                            :item="item"
                                            :sendConsult="sendConsult"/>

                                        <facility-list-item
                                            v-else-if="item.type === 'facility'"
                                            :item="item"/>

                                        <custom-list-item
                                            v-else-if="item.type === 'custom'"
                                            :item="item"/>

                                    </div>

                                    <div class="d-flex justify-space-between align-center pl-2">

                                        <v-tooltip bottom
                                                   nudge-bottom="10"
                                                   nudge-right="50"
                                                   :disabled="$vuetify.breakpoint.xsOnly"
                                                   color="ycmd-black"
                                                   content-class="fade-0 pa-4 pt-2 pb-2">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon dark :color="`${item.is_favorite ? '#ffcc00' : 'grey'}`" v-on="on" @click="toggleContactFavorite(item)" class="ml-2 mr-2">
                                                    <v-icon :size="24">star</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Toggle Favorite</span>
                                        </v-tooltip>

                                    </div>

                                </div>

                            </v-container>

                            <infinite-loader :infiniteHandler="infiniteHandler"
                                            :infiniteId="infiniteId"
                                            :refreshHandler="refreshData"/>

                            </div>

                        <div class="scrollable pa-2 pt-1" style="width: 65%">

                            <div>

                                <div v-if="selectedContact" :key="selectedContact.id">

                                    <provider-item-details v-if="selectedContact.type === 'provider'" :item="selectedContact" :toggleContactFavorite="toggleContactFavorite"/>
                                    <custom-item-details v-else-if="selectedContact.type === 'custom'" :item.sync="selectedContact" :updateContact="updateContact" :toggleContactFavorite="toggleContactFavorite" :onContactDelete="onContactDelete" :isLoading.sync="isLoading"/>
                                    <facility-item-details v-else-if="selectedContact.type === 'facility'" :item="selectedContact" :toggleContactFavorite="toggleContactFavorite"/>

                                </div>

                                <div v-else-if="!selectedContact">
                                    <v-card
                                      class="mx-auto full-width"
                                      outlined
                                    >
                                        <v-card-title class="d-flex justify-center">
                                            <v-icon
                                                large
                                                left
                                            >
                                                info
                                            </v-icon>
                                            <span class="title font-weight-light">Select a contact to view details</span>
                                        </v-card-title>
                                    </v-card>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <template slot="right-panel">

                <div class="i-card__panel i-card__panel__right ycmd-blue-vertical-gradient pa-2 pr-0 pt-0">

                    <div class="i-card__panel__header br-2">

                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="50"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark v-on="on" @click="card.panels.right = false" class="ml-2 mr-2">
                                        <v-icon :size="20">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>

                            <div class="white--text fs-14 ml-3">
                                Search Criteria
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2">

                        </div>
                    </div>

                    <div class="i-card__panel__content white br-2 br_tr-0 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">
                                Content
                            </div>
                        </div>
                    </div>
                </div>

            </template>

            <template slot="bottom-panel">

                <new-contact-form :togglePanels="togglePanels"/>

            </template>

        </i-card>

    </div>

</template>

<script>

    import {mapActions, mapState, mapGetters} from "vuex";
    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import {refreshHandler} from '@/graphql/handler/refreshHandler';

    import InfiniteLoader from '@/components/loader/infinite-loader';
    import CommunicationHeader from "../components/communication-header";
    import InputSearch from "@/components/input-search";

    import SubHeaderContent from '@/components/header/sub-header-content';
    import Counter from '@/components/header/items-counter';
    import FacilityListItem from './components/facility-list-item';
    import ProviderListItem from './components/provider-list-item';
    import CustomListItem from './components/custom-list-item';
    import ProviderItemDetails from './components/provider-item-details';
    import CustomItemDetails from './components/custom-item-details';
    import FacilityItemDetails from './components/facility-item-details';
    import NewContactForm from './components/new-contact-form';

    import {formatPhoneNumber} from '@/methods/globalMethods';
    import GET_CONTACTS from '@/graphql/queries/phoenix/ycmdContactGet';
    import FAVORITE_CONTACT from '@/graphql/mutations/phoenix/ycmdContactFavorite';
    import DELETE_CUSTOM_CONTACT from '@/graphql/mutations/phoenix/ycmdContactDelete';

    export default {
        name: 'contacts',
        components: {
            'communication-header': CommunicationHeader,
            'input-search': InputSearch,
            'sub-header-content': SubHeaderContent,
            'counter': Counter,
            'facility-list-item': FacilityListItem,
            'provider-list-item': ProviderListItem,
            'custom-list-item': CustomListItem,
            'custom-item-details': CustomItemDetails,
            'provider-item-details': ProviderItemDetails,
            'facility-item-details': FacilityItemDetails,
            'new-contact-form': NewContactForm,
            'infinite-loader': InfiniteLoader,
            Loading,
            InfiniteLoading
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                format,
                parseISO,
                formatPhoneNumber,
                testArr:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                contactFilterSelected: null,
                contactFilters: [
                    {
                        name: 'All',
                        value: null
                    },
                    {
                        name: 'Provider',
                        value: 'provider'
                    },
                    {
                        name: 'Facility',
                        value: 'facility'
                    },
                    {
                        name: 'Custom',
                        value: 'custom'
                    },
                ],
                isLoading: false,
                isLoadingContacts: false,
                items: [],
                item: {},
                currentItems: 0,
                totalItems: 0,

                page: 1,
                limit: 20,
                searchValue: '',
                searchFavorite: false,
                infiniteId: 1,
                loadingMessageResult: '',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },

                selectedContact: null
            }
        },
        created() {},
        mounted() {
            console.log('createCustomContactData mounted: ', this.createCustomContactData);
        },
        activated() {},
        watch: {
            searchFavorite(value) {
                this.submitSearch();
            },
            contactFilterSelected(value) {
                this.submitSearch();
            },
            getUserID(value) {
                this.refreshData();
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            onSearchClick(e, value) {
                this.submitSearch();
            },
            submitSearch() {
                console.log('reset contact');
                this.selectedContact = null;

                this.items = [];
                this.page = 1;
                this.infiniteId += 1;
            },
            onPressContact(item) {
                console.log('setting to: ', item);
                this.selectedContact = item;
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            togglePanels(which, what) {
                this.card.panels[which] = what
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => {
                    console.log('router error: ', err)
                });
            },
            async sendConsult() {

            },
            updateContact(item) {
                for (let contact in this.items) {
                    if (this.items[contact].id === item.id) {
                        // set item
                        this.items[contact] = {...this.items[contact], ...item};
                        // re-select selected contact
                        this.selectedContact = this.items[contact];
                    }
                }
            },
            refreshData() {
                this.selectedContact = null;

                this.currentItems = 0;
                this.totalItems = 0;
                this.page = 1;
                this.search = '';
                this.items = [];

                this.infiniteId++;
            },
            async onContactDelete(item) {
                this.isLoading = true;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: DELETE_CUSTOM_CONTACT,
                        variables: {
                            custom_contact_id: item.id
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;

                    console.log('response: ', response);

                    const { ycmdContactDelete } = response.data

                    if (ycmdContactDelete) {
                        // delete locally
                        for (let index in this.items) {
                            if (this.items[index].id === item.id) {
                                // remove details page for this item
                                this.selectedContact = null;
                                this.items.splice(index, 1);
                            }
                        }

                        this.$toasted.success(`Successfully deleted contact`);
                    } else {
                        // data did not come back
                        this.$toasted.error(`There was an error deleting the contact`);
                        console.log('ycmdContactDelete did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.$toasted.error(`Caught an error deleting contact`);
                    console.log('WARNING: Caught error - ', e);
                    // defaultErrorHandler(e);
                }
            },
            async toggleContactFavorite(item) {
                // for (let contact in this.items) {
                //     if (this.items[contact].id === item.id) {
                //         this.$set(this.items[contact], 'is_favorite', !this.items[contact].is_favorite);
                //     }
                // }
                this.$set(item, 'is_favorite', !item.is_favorite);
                // item.is_favorite = !item.is_favorite;
                try {
                    const response = await this.$apollo.mutate({
                        mutation: FAVORITE_CONTACT,
                        variables: {
                            user_id: item.type === 'provider' ? item.user_id : null,
                            facility_id: item.type === 'facility' ? item.facility_id : null,
                            custom_contact_id: item.type === 'custom' ? item.id : null,
                            mode: item.is_favorite ? 'add' : 'remove'
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    console.log('response: ', response);

                    const { ycmdContactFavorite } = response.data

                    if (ycmdContactFavorite) {
                        // this.$toasted.success(`Successfully favorited message`);
                    } else {
                        // data did not come back
                        this.$set(item, 'is_favorite', !item.is_favorite);
                        this.$toasted.error(`There was an error favoriting the contact`);
                        console.log('ycmdContactFavorite did not come back: ', response);
                    }
                } catch (e) {
                    this.$set(item, 'is_favorite', !item.is_favorite);
                    this.$toasted.error(`Caught an error favoriting contact`);
                    console.log('WARNING: Caught error - ', e);
                    // defaultErrorHandler(e);
                }
            },
            async infiniteHandler($state) {

                this.isLoadingContacts = true;
                console.log('search value: ', this.searchValue);

                try {
                    const response = await this.$apollo.query({
                        query: GET_CONTACTS,
                        variables: {
                            page: this.page,
                            limit: this.limit,
                            search: this.searchValue,
                            contact_types: this.contactFilterSelected,
                            is_favorite: this.searchFavorite,
                            sort_field: 'last_name',
                            sort_direction: 'asc'
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    // is_favorite: this.searchValue ? null : this.searchFavorite,

                    console.log('response: ', response);
                    this.isLoadingContacts = false;

                    if (response.data && response.data.ycmdContactGet) {

                        if (response.data.ycmdContactGet.contacts.length) {
                            this.items.push(...response.data.ycmdContactGet.contacts);
                            this.page++;

                            $state.loaded();
                        } else {
                            // $state.loaded();
                            $state.complete();
                        }

                        // Update count
                        this.currentItems = this.items.length;
                        if (response.data.ycmdContactGet.total_count) {
                            this.totalItems = response.data.ycmdContactGet.total_count;
                        }

                    } else {
                        // data did not come back
                        //console.log('pcpMessages data did not come back or is finished: ', response);
                        this.loadingMessageResult = 'No results were found';
                        $state.complete();
                    }
                } catch (e) {
                    this.isLoadingContacts = false;
                    $state.complete();
                    // defaultErrorHandler(e);
                }
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                isMenuRightOpen: state => state.menuRight.open
            }),
            ...mapGetters(
                'profile', ['getUserID']
            )
        }
    }

</script>

<style scoped>

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
