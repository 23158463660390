export default [
    {
        name: 'Communications', icon: 'dynamic_feed',
        items: [
            { name: 'Messages', icon: 'drafts', route: 'COMMUNICATION.messages', roleRequired: 'messages', count: 0 },
            { name: 'Voicemail', icon: 'voicemail', route: 'COMMUNICATION.voicemail', roleRequired: 'voicemail', count: 0 },
            { name: 'Calls', icon: 'phone', route: 'COMMUNICATION.call-log', roleRequired: 'calls', count: 0 },
            { name: 'Contacts', icon: 'contact_page', route: 'COMMUNICATION.contacts', roleRequired: 'contacts', count: 0 },
            { name: 'Notifications', icon: 'chat', route: 'COMMUNICATION.notifications', roleRequired: 'notifications', count: 0 },
            { name: 'Chat', icon: 'chat_bubble', route: 'COMMUNICATION.chat', roleRequired: 'chat', count: 0 },
            // { name: 'Notifications', icon: 'speaker_phone', route: '', count: 0 },
            // { name: 'Chat', icon: 'chat', route: '', count: 3 },
            // { name: 'Bulletins', icon: 'announcement', route: '', count: 134 },
        ]
    },
    {
        name: 'Patients', icon: 'tag_faces',
        items: [
            // { name: 'Census', icon: 'assignment', route: 'PATIENTS.view', count: 0 },
            { name: 'Transfers', icon: 'transfer_within_a_station', route: 'PATIENTS.patients-transfer', roleRequired: 'transfer', count: 0 },
            { name: 'Active Patients', icon: 'chat', route: 'PATIENTS.notifications', roleRequired: 'notifications', count: 0 },
        ]
    },
    {
        name: 'Scheduling', icon: 'event', url: 'https://scheduling.youcallmd.com',
        items: [
            { name: 'Scheduling', icon: 'event', route: 'SCHEDULING', roleRequired: 'scheduling', count: 0 },
            { name: 'Reports', icon: 'list_alt', route: 'SCHEDULING.report', roleRequired: 'scheduling', count: 0,
                childItems: []
                    //{name: 'Scheduling', icon: 'chat_bubble', params: {report_id: 'vmm'}}
                //]
            },
        ]
    },
    {
        name: 'EMR', icon: 'health_and_safety',
        items: [
            { name: 'Schedule', icon: 'calendar_month', route: 'EMR.schedule', roleRequired: 'emr',  count: 0 },
        ]
    },    
    {
        name: 'Administration', icon: 'settings',
        items: [
            { name: 'Profile', icon: 'settings', route: 'ADMINISTRATION.profile',  count: 0 },
            { name: 'Add User', icon: 'person_add', route: 'ADMINISTRATION.user', roleRequired: 'user', count: 0 },
            { name: 'Edit Group', icon: 'groups', route: 'ADMINISTRATION.group', roleRequired: 'group', count: 0 },
            { name: 'Support Ticket', icon: 'contact_support', route: 'ADMINISTRATION.ticket',  count: 0 },
        ]
    }
];
