<template>

  <div class="main-menu">

    <v-navigation-drawer app dark absolute
                         :value="isMenuOpen"
                         :permanent="isMenuOpen && $vuetify.breakpoint.name !== 'xs'"
                         :temporary="isMenuOpen && $vuetify.breakpoint.name === 'xs'"
                         :width="$vuetify.breakpoint.name === 'xs' ? '100%' : 220"
                         class="transparent pl-1 pb-2"
                         style="border: 0 !important;">

      <div class="d-flex flex-column full-width full-height ycmd-blue-vertical-gradient br-2">
        <div class="d-flex align-start pl-4 pb-4 pt-2 " style="height: 84px;background-color: #125ea3;">
          <img src="/images/ycmdlogo.png" class="mb-1" style="height: 24px;"/>
          <div class="white--text pr-0 mr-1" style="width:100%;text-align: right">
            <v-btn icon dark
                  @click.stop="toggleMenuLocal"
                  ref="toggleMenu"
                  class="ml-1 mr-0 pb-2">
              <v-icon :size="22">{{ isMenuOpen ? 'menu_open' : 'menu' }}</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="d-flex flex-column full-width">

          <div class="d-flex full-width white br_tl-2 br_tr-2 ">

            <div class="d-flex align-end justify-center flex-grow-1">
              <template v-if="profile && profile.avatar">
                <img :src="profile.avatar" class="pt-2 pl-2" style="max-height:120px" />
              </template>
              <template v-else>
                <v-icon color="ycmd-medium-blue" :size="96" style="bottom:-14px">person</v-icon>
              </template>
            </div>

          </div>

          <div class="d-flex" style="border-bottom: solid 2px #125ea3">

            <div class="d-flex flex-column justify-center align-center full-width white--text pa-2 pb-0">

              <div class="pt-3 pb-2 fs-13">{{ profile.firstName }} {{ profile.lastName }}</div>

              <div class="d-flex full-width pl-1 pt-4 pr-1">

                <v-switch flat dark
                          color="ycmd-light-blue"
                          v-model="online"
                          class="ma-0"
                          :label="`${online ? 'Online' : 'Offline'}`" />
                <v-spacer />
                <div>
                  <v-tooltip bottom
                             nudge-bottom="10"
                             nudge-right="50"
                             :disabled="$vuetify.breakpoint.xsOnly"
                             color="ycmd-black"
                             content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                      <v-btn @click="logUserOut" icon dark v-on="on">
                        <v-icon :size="18" class="mb-1">power_settings_new</v-icon>
                      </v-btn>
                    </template>
                    <span>Log out</span>
                  </v-tooltip>
                </div>
              </div>

            </div>
          </div>

        </div>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card br-2 pr-0">

          <div class="i-card__main pa-0">

            <div class="i-card__main__content white--text pt-2">

              <div class="i-card__scrollable">

                  <template v-if="filteredList && filteredList.length > 0">

                    <v-list dark dense :key="`app_id_${ appIndex }`">

                        <template v-for="(item, i) in getDrawerItems">
                          <v-list-item link @click="changeMenuItem(i, item.route)"
                                       :key="`item_id_${ i }`"
                                       :class="`pt-1 ${ item.route === $route.name ? 'white blue--text' : '' }`"
                                       style="min-height: 56px">
                            <v-list-item-action class="mr-4">
                              <template>
                                <v-icon :size="18"
                                        :color="`${ item.route === $route.name ? 'ycmd-medium-blue' : 'white' }`"
                                        class="pl-1">{{ item.icon }}</v-icon>
                              </template>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title class="fs-14">
                                <div class="d-flex align-center pt-2 pb-2">
                                  <div :class="`d-flex flex-grow-1 ${ item.route === $route.name ? 'ycmd-medium-blue--text' : '' }`">{{ item.name }}</div>
                                  <template v-if="item.count > 0">
                                    <div class="d-flex align-center justify-center pink fw-500 fs-11 text-right mr-1 white--text"
                                         style="height: 22px; min-width: 22px; border-radius: 22px; padding: 4px 8px 4px 8px">
                                      {{ formatCount(item.count) || 2 }}
                                    </div>
                                  </template>
                                </div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <template
                            v-if="item.name === 'Messages' && item.route === $route.name && last5Providers && last5Providers.length"
                          >
                            <v-list-item link
                                        @click.stop="sendToProvider(subitem)"
                                        :class="`py-0 my-0 pr-0`"
                                        v-for="(subitem, i2) in last5Providers"
                                        :key="`messages_si_${i2}`"
                                        >

                              <v-list-item-content class="text-right py-0 my-0 ">
                                <div class="d-flex align-right py-0 my-0">
                                  <v-spacer />
                                  <div :class="`d-flex`">{{ subitem.name }}</div>
                                </div>
                              </v-list-item-content>

                              <v-list-item-action class="mr-4 py-0 my-0">
                                <template>
                                  <v-icon :size="18"
                                          :color="'white'"
                                          class="pl-1">{{ subitem.icon }}</v-icon>
                                </template>
                              </v-list-item-action>

                            </v-list-item>
                          </template>

                          <template
                            v-if="item.childItems && item.childItems.length"
                          >
                            <v-list-item link
                                        @click.stop="childItemClicked(childitem)"
                                        :class="`py-0 my-0 pr-0`"
                                        v-for="(childitem, i3) in item.childItems"
                                        :key="`item_${item.name}_${childitem.name}_${i3}`"
                                        >

                              <v-list-item-content class="text-right py-0 my-0 ">
                                <div class="d-flex align-right py-0 my-0">
                                  <v-spacer />
                                  <div :class="`d-flex`">{{ childitem.name }}</div>
                                </div>
                              </v-list-item-content>

                              <v-list-item-action class="mr-4 py-0 my-0">
                                <template>
                                  <v-icon :size="18"
                                          :color="'white'"
                                          class="pl-1">{{ childitem.icon }}</v-icon>
                                </template>
                              </v-list-item-action>

                            </v-list-item>
                          </template>

                        </template>

                    </v-list>

                  </template>
              </div>
            </div>
          </div>

        </i-card>

      </div>

    </v-navigation-drawer>

    <v-dialog v-model="dialog" persistent max-width="240">

      <v-card color="ycmd-black" dark style="padding-top: 15px;">
        <v-card-text class="fs-18">
          Do you want to logout?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="white" @click="logout">Yes</v-btn>
          <v-btn text color="white" @click="dialog = false">No</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>

</template>

<script>

  import { mapActions, mapState, mapGetters  } from "vuex";
  import { clearLocalStorage, logout } from '@/methods/globalMethods';

  import {defaultErrorHandler} from "@/graphql/handler/errorHandler";
  import innerHeight from "ios-inner-height";
  import CHANGE_ONLINE_STATUS from "@/graphql/mutations/phoenix/ycmdProfileUpdate";

  export default {
    name: 'mainMenu',
    data: () => ({
      logout,
      drawer: false,
      dialog: false,
      menuIndex: -1,
      card: {
        index: 0,
        panels: { left: false, right: false, top: false, bottom: false }
      },
      roles: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      },
      changePasswordForm: false,
      online: true,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showNewPasswordConfirm: false
    }),
    mounted() {

      this.height = innerHeight();
      this.innerHeight = window.innerHeight;

      // Add or remove the header based on which route meta matches
      this.fullscreen = this.$store.getters['layouts/fullscreen'];

      this.online = this.profile.online;
    },
    methods: {
      ...mapActions('menu', ['openMenu', 'closeMenu', 'toggleMenu']),
      ...mapActions('app', ['changeApp', 'changeItem', 'setCount', 'changeItem']),
      ...mapActions('profile', ['setProfile']),
      ...mapActions('rightMenu', ['collapseRightMenu']),
      childItemClicked(item) {
        //this.changeItem(item.itemIndex)
        this.changeMenuItem(item.itemIndex, item.itemRoute, item.params)
      },
      sendToProvider(subitem) {
        this.$store.commit('profile/setOpenBottomPanel', {
          providerId: subitem.providerId,
          location: subitem.location
        });

        if (this.$vuetify.breakpoint.xs) {
          this.closeMenu()
        }

      },
      toggleMenuLocal() {
        this.toggleMenu()
        this.$nextTick(() => {
          if (this.$refs && this.$refs.toggleMenu && this.$refs.toggleMenu.$el) {
            this.$refs.toggleMenu.$el.blur()
          }
        })
      },
      changeMenuItem(itemIndex, route, params) {
        if(this.isRightMenuExpanded)
          this.collapseRightMenu();

        if (route) {
          this.changeItem(itemIndex);
          this.route(route, 'fade-in-transition', params)
        }
      },
      formatCount(count) {
          return count.toString(); //count > 99 ? '99+' : count.toString();
      },
      /*
      changeCount() {
        this.setCount({appIndex: this.newAppIndex, itemIndex: this.newItemIndex, count: this.newCount});
      },
      */
      changePasswordValidation() {
        if (this.$refs.changePasswordRef.validate()) {
          // check if passwords match
          if (this.newPassword === this.newPasswordConfirm) {
            this.changePassword();
          } else {
            this.$toasted.error('Passwords do not match.');
          }
        }
      },
      clearPasswordFields() {
        this.newPasswordConfirm = '';
        this.newPassword = '';
        this.currentPassword = '';

        this.changePasswordForm = false;
      },
      onSwapProfilePicture() {
        // // console.log(this.profilePicture)
        // if (this.profilePicture === 'M') {
        //   localStorage.setItem("profilePicture", "F");
        //   this.profilePicture = 'F';
        //   return;
        // }
        //
        // if (this.profilePicture === 'F') {
        //   localStorage.setItem("profilePicture", "M");
        //   this.profilePicture = 'M';
        //   return;
        // }
      },
      async changeStatus(status) {
        try {
          const response = await this.$apollo.mutate({
            client: 'phoenixClient',
            mutation: CHANGE_ONLINE_STATUS,
            variables: {
              online_status: status ? 'online' : 'offline'
            }
          });
        }
        catch(e) {
          console.log(`An error occurred updating online status: ${ e }`);
        }

      },
      route(name, transition, params) {
        if (!params) {
          params = {}
        }
        params.transition = transition || this.transition
        this.$router.push({
          name: name,
          params: params
        }).catch(err => { console.log('router error: ', err) });
      },
      logUserOut() {
          logout();
      }
    },
    computed: {
        ...mapState('rightMenu', ['isRightMenuExpanded']),
        ...mapState({
            isMenuOpen: state => state.menu.open,
            profile: state => state.profile.info,
            appIndex: state => state.app.index,
            appItemIndex: state => state.app.itemIndex,
            apps: state => state.app.list,
            myRoles: state => state.profile.roles
        }),
        ...mapGetters(
            'app', ['filteredList'],
        ),
        ...mapGetters('profile', ['getLast5Providers']),
        getInnerWidth: function () {
            return window.innerWidth;
        },
        getDrawerItems: function() {
            let list = this.filteredList[this.appIndex].items

            return list
        },
        last5Providers () {
          let arr = []
          let lastProviders = this.getLast5Providers
          if (Array.isArray(lastProviders) ) {
            lastProviders.map(p => {

              arr.push({
                name: p.name,
                icon: 'forward_to_inbox',
                providerId: p.provider_id,
                location: p.location
              })                 
            })
          }

          return arr
        }
    },
    watch: {
        appItemIndex(value) {
            //console.log('app item index: ', this.appItemIndex);
        },
        filteredList(value) {
            const currentRouteName = this.$route.name;
            const drawerSelectedName = value[this.appIndex] && value[this.appIndex].items[this.appItemIndex] ? value[this.appIndex].items[this.appItemIndex].route : null;
            // try to find if the item still exists in current app
            if (value[this.appIndex]) {
                for (let index in value[this.appIndex].items) {
                    if (value[this.appIndex].items[index].route === currentRouteName) {
                        this.changeItem(index);
                        return;
                    }
                }
                if (currentRouteName !== drawerSelectedName) {
                    // set to first drawer item
                    this.changeApp(this.appIndex);
                }
            } else {
                // set to first drawer item
                this.changeApp(0);
            }

        },
        online (status) {

          this.changeStatus(status)
        }
    }
  }
</script>
