<template>

    <div class="view">
        <div class="d-flex flex-column flex-grow-1 relative">

            <transition :name="transition">

                <keep-alive >
                    <component v-bind:is="view_name" :changeView="changeView" :params="view_params" @initialized="componentInitialized"/>
                </keep-alive>

            </transition>
        </div>
    </div>
</template>

<script>

import Chat_Rooms from '@/views/apps/chat/rooms/list.vue'
import Chat_Main from '@/views/apps/chat/main/list.vue'
import Chat_GeneralSettings from '@/views/apps/chat/general-settings/list.vue'
import Chat_Edit from '@/views/apps/chat/edit/list.vue'
import Chat_Add_Participant from '@/views/apps/chat/add-participant/list.vue'
import Chat_Settings from '@/views/apps/chat/settings/list.vue'
import Chat_Details from '@/views/apps/chat/details/list.vue'
import Chat_Message from '@/views/apps/chat/message/list.vue'
/*
import Chat_Profile from '@/views/apps/chat/profile/index.vue'
import Chat_Schedule from '@/views/apps/chat/schedule/index.vue'
*/
    import {mapActions, mapState} from "vuex";

    export default {
        name: 'messages',
        components: {
            'chat-rooms': Chat_Rooms,
            'chat-main': Chat_Main,
            'chat-general-settings': Chat_GeneralSettings,
            'chat-edit': Chat_Edit,
            'chat-add-participant': Chat_Add_Participant,
            'chat-settings': Chat_Settings,
            'chat-details': Chat_Details,
            'chat-message': Chat_Message
        },
        data() {
            return {
                visit_id: 0,
                view_name: 'chat-rooms',
                view_params: {},
                transition: 'next',
                callbacks: {}
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            componentInitialized(name, callback) {
                console.log('name', name)
                console.log('callback', callback)

                this.callbacks[name] = callback
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            changeView({view, transition, params}) {
                console.log(`changeview ${view} transition: ${transition} params`, params)
                if (transition) {
                    this.transition = transition
                } else if (params.transition) {
                    this.transition = params.transition                    
                }
                
                //this.sendEvent(this.view.name, 'unload') not needed
                this.view_name = view;
                this.view_params = params || {};
                this.sendEvent(this.view_name, 'load', params)
            },
            sendEvent (view, event, params) {
                let callback = this.callbacks[view]

                if (callback) {
                    callback(event, params)
                }
            }
        },
        computed: {
        }        
    }

</script>

<style scoped>


</style>
