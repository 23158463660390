import store from '@/store'
import {logout} from '@/methods/globalMethods';

let currentTimer = 0
const inactivityTimer = () => {
    let minutesToTimeout = store.getters['profile/getInactivityTimeoutMinutes']

    if (!minutesToTimeout) {
        //either we are in the process of getting all the user settings
        //or the user has explicitly set a 0 for the timeout.  In either case,
        //we will abort the inactivity timer
        console.log("*** No Timeout minutes.  Aborting Reset timer ***")
        return
    }

    if (currentTimer) {
      console.log('clearing timer', currentTimer)
      clearTimeout(currentTimer)
    }
    currentTimer = setTimeout(()=> {
        console.log("*** Reset timer has expired.  Logging  off ***")
        logout()
    }, minutesToTimeout * 60000) //minutes * 60 * 1000 ms

    console.log(`*** Inactivity timer ${currentTimer} reset for ${minutesToTimeout} minutes from ${new Date()} ***`)  
}

export { inactivityTimer }
