import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store';

import Authenticate from './views/authentication/authenticate.vue'
import Login from './views/authentication/login.vue'

import Register from './views/authentication/register.vue'
import RegisterAuthenticate from './views/authentication/register_authenticate.vue'
import RegisterPassword from './views/authentication/register_password.vue'
import RegisterForgotPassword from './views/authentication/register_forgot_password.vue'

import PCPOptin from './views/authentication/PCPoptin.vue'
import MFACode from './views/authentication/mfa_code.vue'
import SetPassword from './views/authentication/create_password.vue'
import ForgotPassword from './views/authentication/forgot_password.vue'
import AuthenticationContactUs from './views/authentication/contact.vue'
import AuthenticationFAQ from './views/authentication/faq.vue'
import AuthenticationFAQLogin from './views/authentication/faq_login.vue'
import RedirectMobile from './views/authentication/redirect_mobile.vue'

import Admin from './views/apps/pcpAppAdmin/admin.vue'
import Providers from './views/apps/pcpAppAdmin/providers/providers.vue'
import Notifications from './views/apps/pcpAppAdmin/notifications/notifications.vue'
import ManageUsers from './views/apps/pcpAppAdmin/manageUsers/manage-users.vue'
import Dashboard from './views/apps/pcpAppAdmin/dashboard/dashboard.vue'

/*
import Scheduling from './views/apps/scheduling/scheduling.vue'
import SchedulingCalendar from './views/apps/scheduling/calendar/calendar.vue'
*/

import PageNotFound from './views/page-not-found.vue'
import PageNoAccess from './views/page-no-access.vue'
import PageNotFoundNoOptions from './views/page-not-found-no-options.vue'

import Scripts from './views/apps/scripts/scripts.vue'
import ProviderLink from './views/apps/scripts/link/provider_link.vue'
import RemoveFromChat from './views/apps/scripts/removeFromChat/remove_from_chat.vue'
import AutoInjectShifts from './views/apps/scripts/autoInjectShifts/auto_inject_shifts.vue'
import CannedResponse from './views/apps/scripts/cannedResponse/canned_response.vue'
import UserImport from './views/apps/scripts/userImport/user-import.vue'
import FacilityDelete from './views/apps/scripts/facilityDelete/facility_delete.vue'
import Broadcast from './views/apps/scripts/broadcast/broadcast.vue'
import AutoEnroll from './views/apps/scripts/autoEnroll/auto_enroll.vue'

import Group from './views/apps/group/group.vue'
import GroupInbox from './views/apps/group/inbox/inbox.vue'
import GroupEditProfile from './views/apps/group/profile/edit-profile.vue'

import PCPUser from './views/apps/pcpApp/pcp_user.vue';
import PCPUserNotifications from './views/apps/pcpApp/notifications/notifications.vue'
import PCPUserNotificationsFiltered from './views/apps/pcpApp/notificationsFiltered/notifications-filtered.vue'

import Billing from './views/apps/billing/billing.vue'
import UpdateGroup from './views/apps/billing/updateGroup/update-group.vue'

import Sample from './views/apps/pcpAppAdmin/sample/sample.vue'
import SampleSubscribe from './views/apps/pcpAppAdmin/sample/subscribeToMessages.vue'

import { checkHasTokensPhoenix, checkHasTokensPCP } from './methods/globalMethods';

import Communication from './views/apps/communication/index.vue';
import Communication_Voicemail from './views/apps/communication/voicemail/index.vue';
import Communication_Messages from './views/apps/communication/messages/index.vue';
import Communication_Call_Log from './views/apps/communication/callLog/index.vue';
import Communication_Contacts from './views/apps/communication/contacts/index.vue';
import Communication_Notifications from './views/apps/communication/notifications/index.vue';

import Patients from './views/apps/patients/patients/index.vue';
import Patients_Transfer from './views/apps/patients/patientsTransfer/index.vue';
import Patients_Notifications from './views/apps/patients/notifications/index.vue';

import Administration from './views/apps/administration/index.vue';
import Administration_Edit_Profile from './views/apps/administration/editProfile/page.vue';
import Administration_New_Account from './views/apps/administration/newAccount/index.vue';
import Administration_Group from './views/apps/administration/group/index.vue';
import Administration_Ticket from './views/apps/administration/ticket/page.vue';

import Communication_Chat from './views/apps/communication/chat/index.vue';

import ProviderPage from './views/provider-page/page'

import EMR from './views/apps/emr/index.vue'
import SchedulingNew from './views/apps/scheduling-new/index.vue'
import report from './views/apps/reporting/report.vue'

const adminRoles = ['pcp_admin', 'pcp_group_admin', 'pcp_facility_admin', 'pcp_analyst']
const adminRolesNoAnalyst = ['pcp_admin', 'pcp_group_admin', 'pcp_facility_admin']
const groupRoles = ['member', 'member.admin']

function checkIsAuthenticated(meta) {
    if (meta.authentication || meta.phoenixAuthentication) return true;
    return false;
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/auth'
  },
  {
    path: '/auth',
    component: Authenticate,
    redirect: '/auth/login',
    meta: { fullscreen: true },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login
      },
      {
        path: 'register',
        name: 'register',
        component: Register
      },
      {
        path: 'registerAuth',
        name: 'registerAuthenticate',
        component: RegisterAuthenticate
      },
      {
        path: 'registerPassword',
        name: 'registerPassword',
        component: RegisterPassword
      },
      {
        path: 'registerForgotPassword',
        name: 'registerForgotPassword',
        component: RegisterForgotPassword
      },
      {
        path: 'pcpoptin',
        name: 'pcpoptin',
        component: PCPOptin
      },
      {
        path: 'forgot',
        name: 'forgotPassword',
        component: ForgotPassword
      },
      {
        path: 'code',
        name: 'mfaCode',
        component: MFACode
      },
      {
        path: 'newpassword',
        name: 'newPass',
        component: SetPassword
      },
      {
        path: 'faq',
        name: 'authFAQ',
        component: AuthenticationFAQ
      },
      {
        path: 'faqLogin',
        name: 'authFAQLogin',
        component: AuthenticationFAQLogin
      },
      {
        path: 'redirectmobilepcp',
        name: 'redirectMobile',
        component: RedirectMobile
      },
      {
        path: 'contactUs',
        name: 'authContactUs',
        component: AuthenticationContactUs
      }
    ]
  },
  {
    path: '/admin',
    component: Admin,
    redirect: '/admin/providers',
    meta: {
      fullscreen: false,
       authentication: true,
       whitelistRoles: adminRoles
    },
    children: [
      {
        path: 'dashboard',
        name: 'ADMIN.dashboard',
        component: Dashboard,
        meta: {
          authentication: true,
          whitelistRoles: adminRoles
        }
      },
      {
        path: 'providers',
        name: 'ADMIN.providers',
        component: Providers,
        meta: {
          authentication: true,
          whitelistRoles: adminRoles
        }
      },
      {
        path: 'notifications',
        name: 'ADMIN.notifications',
        component: Notifications,
        meta: {
          authentication: true,
          whitelistRoles: adminRolesNoAnalyst,
          blacklistRoles: ['pcp_analyst']
        }
      },
      {
        path: 'sample',
        name: 'ADMIN.sample',
        component: Sample,
        meta: {
          authentication: true,
          whitelistRoles: adminRoles
        }
      },
      {
        path: 'sampleSubscribe',
        name: 'sampleSubscribeToMessages',
        component: SampleSubscribe,
        meta: {
          authentication: true,
          whitelistRoles: adminRoles
        }
      },
      {
        path: 'mngusers',
        name: 'ADMIN.manageUsers',
        component: ManageUsers,
        meta: {
          authentication: true,
          whitelistRoles: adminRolesNoAnalyst,
          blacklistRoles: ['pcp_analyst']
        }
      }
    ]
  },
  /*
  {
    path: '/scheduling',
    component: Scheduling,
    redirect: '/scheduling/calendar',
    meta: { fullscreen: false },
    children: [
      {
        path: 'calendar',
        name: 'SCHEDULING.calendar',
        component: SchedulingCalendar
      }
    ]
  },
  */
  {
    path: '/scripts',
    component: Scripts,
    redirect: '/scripts/link-providers',
    meta: {
      authentication: true,
      fullscreen: false,
      whitelistRoles: ['pcp_admin']
    },
    children: [
      {
        path: 'link-providers',
        name: 'SCRIPTS.link-providers',
        component: ProviderLink,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'remove-from-chat',
        name: 'SCRIPTS.remove-from-chat',
        component: RemoveFromChat,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'auto-inject-shifts',
        name: 'SCRIPTS.auto-inject-shifts',
        component: AutoInjectShifts,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'add-canned-response',
        name: 'SCRIPTS.add-canned-response',
        component: CannedResponse,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'user-import',
        name: 'SCRIPTS.user-import',
        component: UserImport,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'facility-delete',
        name: 'SCRIPTS.facility-delete',
        component: FacilityDelete,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'broadcast',
        name: 'SCRIPTS.broadcast',
        component: Broadcast,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
      {
        path: 'auto-enroll',
        name: 'SCRIPTS.auto-enroll',
        component: AutoEnroll,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      },
    ]
  },
  {
    path: '/billing',
    component: Billing,
    redirect: '/billing',
    meta: {
      authentication: true,
      fullscreen: false,
      whitelistRoles: ['pcp_admin']
    },
    children: [
      {
        path: 'update-group',
        name: 'BILLING.group-update',
        component: UpdateGroup,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_admin']
        }
      }
    ]
  },
  {
    path: '/pcp-user',
    component: PCPUser,
    redirect: '/pcp-user',
    meta: {
      authentication: true,
      fullscreen: false,
      whitelistRoles: ['pcp_user']
    },
    children: [
      {
        path: 'notifications',
        name: 'PCPUSER.notifications',
        component: PCPUserNotifications,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_user']
        }
      },
      {
        path: 'notifications-filtered',
        name: 'PCPUSER.notifications-filtered',
        component: PCPUserNotificationsFiltered,
        meta: {
          authentication: true,
          whitelistRoles: ['pcp_user']
        }
      }
    ]
  },
  {
    path: '/communication',
    component: Communication,
    meta: {
      phoenixAuthentication: true,
      fullscreen: false
    },
    children: [
        {
            path: 'messages',
            name: 'COMMUNICATION.messages',
            component: Communication_Messages,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['messages']
            }
        },
        {
            path: 'call-log',
            name: 'COMMUNICATION.call-log',
            component: Communication_Call_Log,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['calls']
            }
        },
        {
            path: 'contacts',
            name: 'COMMUNICATION.contacts',
            component: Communication_Contacts,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['contacts']
            }
        },
        {
            path: 'voicemail',
            name: 'COMMUNICATION.voicemail',
            component: Communication_Voicemail,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['voicemail']
            }
        },
        {
            path: 'notifications',
            name: 'COMMUNICATION.notifications',
            component: Communication_Notifications,
            meta: {
                whitelistRoles: ['notifications']
            }
        },
        {
          path: 'chat',
          name: 'COMMUNICATION.chat',
          component: Communication_Chat,
          meta: {
              phoenixAuthentication: true,
              whitelistRoles: ['chat']
          }
        },
    ]
  },
  {
    path: '/patients',
    component: Communication,
    redirect: '/patients/patients',
    meta: {
      phoenixAuthentication: true,
      fullscreen: false
    },
    children: [
        {
            path: 'view',
            name: 'PATIENTS.view',
            component: Patients,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['census']
            }
        },
        {
            path: 'patients-transfer',
            name: 'PATIENTS.patients-transfer',
            component: Patients_Transfer,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['transfer']
            }
        },
        {
            path: 'patients-notifications',
            name: 'PATIENTS.notifications',
            component: Patients_Notifications,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['notifications']
            }
        }
    ]
  },
  {
    path: '/administration',
    component: Administration,
    redirect: '/administration/new-account',
    meta: {
      phoenixAuthentication: true,
      fullscreen: false
    },
    children: [
        {
            path: 'edit-profile',
            name: 'ADMINISTRATION.profile',
            component: Administration_Edit_Profile,
            meta: {
                phoenixAuthentication: true
            }
        },
        {
            path: 'user',
            name: 'ADMINISTRATION.user',
            component: Administration_New_Account,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['group']
            }
        },
        {
            path: 'group',
            name: 'ADMINISTRATION.group',
            component: Administration_Group,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['group']
            }
        },
        {
          path: 'support-ticket',
          name: 'ADMINISTRATION.ticket',
          component: Administration_Ticket,
          meta: {
              phoenixAuthentication: true
          }
        },

    ]
  },
  {
    path: '/emr',
    component: Communication,
    meta: {
      phoenixAuthentication: true,
      fullscreen: false
    },
    children: [
        {
            path: 'schedule',
            name: 'EMR.schedule',
            component: EMR,
            meta: {
                phoenixAuthentication: true,
                whitelistRoles: ['emr']
            }
        },
    ]
  },    
  {
    path: '/scheduling',
    component: Communication,
    meta: {
      phoenixAuthentication: true,
      fullscreen: false
    },
    children: [
        {
            path: 'schedule',
            name: 'SCHEDULING',
            component: SchedulingNew,
            meta: {
                phoenixAuthentication: true
            }
        },
        {
          path: 'schedule/report/:report_id',
          name: 'SCHEDULING.report',
          component: report,
          meta: {
              phoenixAuthentication: true
          }
      },
    ]
  },    

  {
    path: '/provider-page/:id',
    component: ProviderPage,
    meta: { fullscreen: true },
  },  
  {
    path: "/no-access",
    name: '403',
    component: PageNoAccess
  },
  {
    path: "/not-found",
    name: '404NoOptions',
    component: PageNotFoundNoOptions
  },  
  {
    path: "*",
    name: '404',
    component: PageNotFound
  }
];

Vue.use(VueRouter);

const Router = new VueRouter({
  mode: 'history',
  routes
});

function route(name, direction) {
    Router.push({
        name: name, params: {
            transition: direction
        }
    }).catch(err => { console.log('router error: ', err) });
}

Router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.fullscreen)) {
    store.commit('layout/setFullscreen', true)
  }
  else {
    store.commit('layout/setFullscreen', false)
  }

  // console.log('to: ', to.name, 'from: ', from.name);

  ////
  // check authentication
  ////
  // check if access token is needed for PCP
  if (to.meta.authentication && !checkHasTokensPCP()) {
    console.log('PCP token does not exist');
    next({ name: 'login' });
    return;
  }
  // check if phoenix token is needed
  if (to.meta.phoenixAuthentication && !checkHasTokensPhoenix()) {
    console.log('where are we going to: ', to);
    console.log('Phoenix token does not exist');
    next({ name: 'login' });
    return;
  }
  ////

  // check if you have roles
  const roles = new Set(JSON.parse(localStorage.getItem("userRoles")));

  if (!roles && checkIsAuthenticated(to.meta)) {
        console.log('roles do not exist');
        if (to.name !== 'login') {
            next({ name: 'login' });
            console.log('Roles do not exist, routing back to login...');
            return;
        }
        next();
        return;
  };

  // check whitelist
  if (to.meta && to.meta.whitelistRoles) {
    for (let role in to.meta.whitelistRoles) {
      const currentRole = to.meta.whitelistRoles[role];
      // if you have the role, go next
      if (roles.has(currentRole)) {
        next();
        return;
      }
    }

    // if did not pass the whitelist check
    console.log('did not pass wl');
    next({ name: '403' });
    return;
  }

  // check blacklist
  if (to.meta && to.meta.blacklistRoles) {
    for (let role in to.meta.blacklistRoles) {
      const currentRole = to.meta.blacklistRoles[role];
      // if you have the role, go next
      if (roles[currentRole]) {
        next({ name: '403' });
        return;
      }
    }
  }

  next();
});

export default Router
