import gql from 'graphql-tag'

export default gql`query ycmdUserSearch($search: String, $limit: Int, $roles: [YcmdRole], $facility_ids: [ID], $user_id: ID, $include_searching_user: Boolean, $exclude_group_filter: Boolean) {
	ycmdUserSearch(search: $search, limit: $limit, roles: $roles, facility_ids: $facility_ids, user_id: $user_id, include_searching_user: $include_searching_user, exclude_group_filter: $exclude_group_filter) {
		group_name
		group_id
		users {
			id
			first_name
			last_name
			group_id
		}
	}
}`
