<template>

    <v-container fluid class="ycmd-light-gray b b-1 br-2 pa-4">

        <v-row no-gutters>

            <slot name="section-content"></slot>

        </v-row>

    </v-container>

</template>

<script>

    export default {
        name: 'sectionContent',
        components: {
        },
        props: {

        },
        data: () => ({
        }),
        mounted() {},
        methods: {

        },
        computed: {

        }
    }
    </script>

<style scoped>

</style>
